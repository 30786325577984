import construpApi from "@/config/construpApi";

export default {
  uploadFile(_, file) {
    return construpApi.post("/upload-file", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadFiles(_, files) {
    return construpApi.post("/upload-files", files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteFile(_, filename) {
    return construpApi.delete(`/delete-file/${filename}`);
  },
};
