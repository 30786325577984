import api from '@/config/api';

/* eslint-disable */
export default {
  getAllEmpresa({}, id) {
    return api.get(`empresa/get-all-data/${id}`);
  },
  deleteEmpresa({}, id) {
    return api.get(`empresa/delete/${id}`);
  },
  updateEmpresa({}, data) {
    return api.post(`/empresa/edit/${data.id}`, data);
  },
  newEmpresa({}, data) {
    return api.post('/empresa/new', data);
  },
  newProfessionalClient({}, data) {
    return api.post('/professional/new', data);
  }
};
