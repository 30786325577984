export default {
  getUserId() {
    const user = localStorage.getItem('user');

    if (!user) return 0;

    const { user_id } = JSON.parse(user);

    return user_id || 0;
  },
  getUserType() {
    const user = localStorage.getItem('user');

    if (!user) return 0;

    const { type } = JSON.parse(user);

    return type || 0;
  },
};
