import api from '@/config/api';

export default {
  listAboutCompany({ commit }, construpUserId) {
    return api.get(`/app/about-company/${construpUserId}`)
      .then((response) => {
        commit('setAboutCompany', response.data.data || {});

        return response.data.data || {};
      });
  },
};
