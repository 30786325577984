import api from '@/config/api';

export default {
  getAllCustomizations(_, userId) {
    return api.get(`/customization/${userId}`);
  },
  getAllLinks(_, userId) {
    return api.get(`/listar-links/${userId}`);
  },
};
