
export default [
  {
    path: "/meus-pontos",
    name: "meus-pontos",
    component: () => import("@/views/premio/MeusPontos.vue"),
  },
  {
    path: "/premio-disponivel",
    name: "premio-disponivel",
    component: () => import("@/views/premio/PremioDisponivel.vue"),
  },
  {
    path: "/premio-solicitado",
    name: "premio-solicitado",
    component: () => import("@/views/premio/PremioSolicitado.vue"),
  },
  {
    path: "/validar-pontos-externo",
    name: "validar-pontos-externo",
    component: () => import("@/views/premio/ValidarPontosExterno.vue"),
  }
];
    