export default function (value = '') {
    if(value != undefined) {
        let style = '';
        if(value.size != '' && value.size != undefined) {
            let size = `font-size: ${value.size};`;
            style = style.concat(size);
        }
        if(value.color != '' && value.color != undefined) {
            let color = `color: ${value.color};`;
            style = style.concat(color);
        }
        if(value.width != '' && value.width != undefined) {
            let width = `width: ${value.width};`;
            style = style.concat(width);
        }
        if(value.weight != '' && value.weight != undefined) {
            let weight = `font-weight: ${value.weight};`;
            style = style.concat(weight);
        }
        return style;
    }
}