import api from "@/config/construpApi";

export default {
  getTips(_, pageNumber) {
    return api.get(`/blog/${pageNumber}`);
  },
  getTip(_, tipId) {
    return api.get(`/assunto/${tipId}`);
  },
  getRelatedTips(_, tipId) {
    return api.get(`/dica-obra-relacionadas/${tipId}`);
  },
};
