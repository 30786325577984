<template>
  <ion-card>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">CEP</ion-label>
            <ion-input
              ref="input"
              type="text"
              :disabled="loading"
              v-model="fields.cep"
              maxlength="9"
              placeholder="Digite o CEP de entrega desejado"
              @keyup="cepChangeTimeout()"
            ></ion-input>
          </ion-item>
          <error-message :text="errorMessages.cep" />
        </ion-col>
      </ion-row>
      <ion-row class="text-center">
        <ion-col class="font-weight-bold fs-20"> OU </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <base-button
            text="Usar localização atual"
            color="dark"
            :isLoading="loading"
            :icon="icons.locationSharp"
            fill="outline"
            @click="getLocalization()"
          />
        </ion-col>
      </ion-row>
      <ion-row v-if="getCurrentGeolocation.cityName">
        <ion-col>
          {{ getCurrentGeolocation.fullAddress }}
          <p class="text-center warning-text" v-if="!isValidNoLogged && $route.name == 'cart'">
            Esse endereço não está no raio de entrega da loja!
          </p>

        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
} from "@ionic/vue";

import { locationSharp, checkmarkSharp, trash } from "ionicons/icons";
import { mapGetters, mapMutations } from "vuex";
import useGeolocation from "Composition/useGeolocation.js";
import useCep from "Composition/useCep.js";
import maskInput from "Composition/maskInput";
import eventBus from "../../composition/eventBus";

export default {
  name: "LocalizationDataCard",
  data() {
    return {
      loading: false,
      opened: false,
      fields: {},
      icons: {
        locationSharp,
        checkmarkSharp,
        trash,
      },
      errorMessages: {
        cep: "",
      },
      timer: null,
    };
  },
  props: {
    isValidNoLogged:{
      type: Boolean,
    }
  },
  setup() {
    const { getCurrentPosition } = useGeolocation();
    const { getCityByCep } = useCep();

    return { getCurrentPosition, getCityByCep };
  },
  computed: {
    ...mapGetters("customization", ["getMenuHeaderColor"]),
    ...mapGetters("geolocation", ["getCurrentGeolocation"]),
  },
  watch: {
    "fields.cep": function () {
      this.fields.cep = maskInput("cep", this.fields.cep);
    },
  },
  mounted() {
    this.fields = this.getCurrentGeolocation || {};
  },
  methods: {
    ...mapMutations("geolocation", ["setGeolocation"]),
    toggleOpen({ id }) {
      this.opened = !this.opened;

      this.fields = this.getCurrentGeolocation || {};
    },
    cepChangeTimeout() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(async () => {
        this.handleCepChange();
      }, 1000);
    },
    clear() {
      this.toggleOpen({});
    },
    async handleCepChange() {
      this.errorMessages.cep = "";

      this.loading = true;

      const response = await this.getCityByCep(this.fields.cep);

      this.loading = false;

      if (response.invalid) {
        this.errorMessages.cep = "Cep inválido";
        return;
      }

      if (this.$route.name == "cart") {
        eventBus().emitter.emit("valid-event-cart");
      } else if (this.$route.name == "detalhesProdutos") {
        eventBus().emitter.emit("valid-event-datails-product");
      }
    },
    async getLocalization() {
      this.loading = true;

      this.getCurrentPosition()
        .then(() => {
          this.fields.cep = this.getCurrentGeolocation.cep;

          setTimeout(() => {
            if (this.$route.name == "cart") {
              eventBus().emitter.emit("valid-event-cart");
            } else if (this.$route.name == "detalhesProdutos") {
              eventBus().emitter.emit("valid-event-datails-product");
            }
          }, 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    IonRow,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonInput,
    IonCard,
  },
};
</script>

<style scoped>
.toolbar-background {
  background: transparent;
}
.warning-text {
  font-size: 14px;
  color: orangered;
  font-weight: 600;
}
</style>
