<template>
  <div class="text-center">
    <slot name="image">
      <ion-img :src="image"></ion-img>
    </slot>
    <div class="mt-4" v-html="text"></div>
  </div>
</template>

<script>
import { IonImg } from "@ionic/vue";

export default {
  name: "NoContent",
  components: {
    IonImg,
  },
  props: {
    text: {
      type: String,
      default: "Nada encontrado &#x1F622",
    },
    image: {
      type: String,
      default: "assets/vectors/blank.svg",
    },
  },
};
</script>
