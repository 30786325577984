<template>
  <ion-row>
    <loading-component :loading="loading"></loading-component>
    <ion-col size="12" class="text-center mt-4">
      <h1 style="font-size: 2rem;font-weight: bold;">Nova versão disponível</h1>
    </ion-col>

    <ion-col size="12" class="text-center mt-4">
      <img src="https://cms.construp.com.br/storage/upload/block.png" alt="Nova atualização" style="max-width: 80%;">
    </ion-col>

    <ion-col size="12" class="text-center mt-4">
      <p style="font-size: 1.3rem;font-weight: bold;">Atualize seu aplicativo e obtenha uma melhor experiência</p>
    </ion-col>

    <ion-col size="12" class="text-center mt-4">
      <button class="btn-atualizar" @click="openUrl()">Atualizar</button>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol
} from "@ionic/vue";
import { isPlatform } from "@ionic/vue";
import { mapGetters } from "vuex";

export default {
  name: 'BlockCompoent',

  components: {
    IonRow,
    IonCol,
    isPlatform
  },

  data() {
    return {
      url: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters("general", ["getConstrupUserId"]),
  },

  created() {
    this.validUrl();
  },

  methods: {
    validUrl() {
      this.loading = true;
      this.$store.dispatch("customization/getAllLinks", this.getConstrupUserId)
        .then((response) => {
          this.url = response.data.link_android;
          if(isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
            this.url = response.data.link_apple;
          } 
        })
        .finally(() => {
          this.loading = false;
        })
    },
    openUrl() {
      if(this.url != '') {
        window.open(this.url, '_blank');
      }
    }
  }
}
</script>

<style  scoped>
.btn-atualizar {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #FFF;
  background-color: #222;
}
</style>
