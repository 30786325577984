import api from '@/config/api';

/* eslint-disable */
export default {
  getAllClient({}, id) {
    return api.get(`client/client/get-all-data/${id}`);
  },
  deleteClient({}, id) {
    return api.get(`client/delete/${id}`);
  },
  updateClient({}, data) {
    return api.post(`/client/edit/${data.id}`, data);
  },
  newClient({}, data) {
    return api.post('/client/new', data);
  },
  newProfessionalClient({}, data) {
    return api.post('/professional/new', data);
  },
  getCards({}, id) {
    return api.get(`/client/get-cards/${id}`);
  },
  getCardId({}, id) {
    return api.get(`/client/get-card-id/${id}`);
  },
  deleteCard({}, id) {
    return api.delete(`/client/delete-card/${id}`);
  },
  saveNewCard({}, data) {
    return api.post('/client/new-card', data);
  },
  validCpf({}, data) {
    return api.post('/validar-cpf', data);
  },
  validCnpj({}, data) {
    return api.post('/validar-cnpj', data);
  }
};
