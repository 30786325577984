export default function (type, value = '') {
    if(value != undefined && value != '') {
        if(type == 'cpf') {
            value = value.replace(/\D/g, '');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        }
        if(type == 'cep') {
            value = value.replace(/\D/g, '');
            value = value.replace(/(\d{5})(\d)/, '$1-$2');
        }
        if(type == 'cnpj') {
            value = value.replace(/\D/g, '');
            value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        }
        if(type == 'phone') {
            var value = value.replace(/\D/g, "");
            value = value.replace(/^0/, "");
            if (value.length > 10) {
                value = value.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
            } else if (value.length > 5) {
                value = value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
            } else if (value.length > 2) {
                value = value.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
            } else {
                value = value.replace(/^(\d*)/, "($1");
            }
        }
        return value;
    }
}