<template>
  <ion-content>
    <loading-component v-if="device == false" :loading="loading"></loading-component>
    
    <div v-if="device == true">
        <ion-fab vertical="center" horizontal="center" slot="fixed">
        <ion-fab-button class="btn-size" color="danger" @click="stopListening()">
          <ion-icon class="iconCamera" :icon="Icon.micOutline" :style="`color: ${getIconColor}`"></ion-icon>
        </ion-fab-button><br>
        <span class="fs-18 font-align"><strong>Toque para finalizar a fala</strong> </span>
      </ion-fab>
    </div>
    
  </ion-content>
</template>

<script>
import { ref } from "vue";
import { 
  IonIcon,
  IonContent, 
  IonFab, 
  IonFabButton, 
 
} from "@ionic/vue";
import { micOutline } from "ionicons/icons";
import { isPlatform, modalController } from "@ionic/vue";
import { SpeechRecognition } from "@awesome-cordova-plugins/speech-recognition/ngx";
import useToast from "@/config/useToast";
import { mapGetters } from "vuex";
export default {
  name: "VoiceText",
  components: {
    IonIcon,
    IonContent, 
    IonFab, 
    IonFabButton, 
    isPlatform,
    modalController,
  },
  data() {
    return {
      loading: false,
      speechRecognition: new SpeechRecognition(),
      text: "",
      plataforma: "",
      device: false,
    };
  },

  computed: {
    ...mapGetters("customization", ["getIconColor"])
  },

  watch: {
    device() {},
  },

  mounted() {
    this.isApple();
    this.startListening();
  },

  setup() {
    const loading = ref(false);
    const { openToast } = useToast();
    const Icon = ref({
      micOutline,
    });

    return {
      Icon,
      loading,
      openToast,
    };
  },

  methods: {
    isApple() {
      if(isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
        this.device = true;
      }
    },

    getProduct(text) {
      this.cancel();
      this.$router.push({name: 'list-product-search', params: {textToSearch: text}});
    },

    startListening() {
      this.getPermission();
      let options = {
        language: "pt-BR",
      };
      this.speechRecognition.startListening(options).subscribe((result) => {
        if (!result[0]) {
          this.openToast(
            "Não foi possível encontrar o produto, tente novamente!",
            "danger"
          );
          return;
        }
        this.text = result[0];
        this.getProduct(this.text);
      });
    },

    stopListening() {
      this.speechRecognition.stopListening();
      this.cancel();
    },

    getPermission() {
      this.speechRecognition.hasPermission().then((permission) => {
        if (!permission) {
          this.speechRecognition.requestPermission().then((result) => {
            if (result == "OK") {
              let options = {
                language: "pt-BR",
              };
              this.speechRecognition
                .startListening(options)
                .subscribe((result) => {
                  this.text = result[0];
                  this.getProduct(this.text)
                });
            }
          });
        }
      });
    },

    cancel() {
      return modalController.dismiss(null, "cancel");
    },
  },
};
</script>
<style scoped>
.iconCamera {
  font-size: 40px;

}

.btn-size{
  width: 75px;
  height: 75px;
}
.font-align{
  margin-left: -65px;
}
</style>