// import api from "@/config/api";

export default {
  handleSavedGeolocation({ commit }) {
    const geolocationSaved = localStorage.getItem('currentGeolocation');

    if (!geolocationSaved) return;

    commit('setGeolocation', JSON.parse(geolocationSaved));
  },
};
