import api from "@/config/api";

export default {
  getFreight({ commit }, userId) {
    return api.get(`/freights/${userId}`).then((response) => {
      const { data } = response.data || {};

      commit("setFreight", data || {});

      return response.data || {};
    });
  },
};
