import IMAGE_ORIGIN from "@/enums/ImagesOrigin.js";

/**
 * @param {string} imageUrl 
 * @param {string} origin 
 * @returns 
 */
export default function (imageUrl, origin) {
  if (origin === IMAGE_ORIGIN.BELENUS) {
    return "https://storage.googleapis.com/fotos-prd/" + imageUrl;
  }

  return process.env.VUE_APP_FOTOS_URL + imageUrl;
}
