import api from '@/config/api';

export default {
  getAllSocialMedias({ commit }, construpUserId) {
    return api.get(`/app/social-medias/${construpUserId}`)
      .then((response) => {
        commit('setSocialMedias', response.data.data || []);

        return response.data.data || [];
      });
  },
};
