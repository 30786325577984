import api from "@/config/api";

export default {
  calculateMelhorEnvioFreight({ rootGetters }, fields) {
    return api.post(`/integration/melhor-envio/calculate-freight`, fields, {
      headers: {
        Authorization: `Bearer ${rootGetters.getToken}`,
      },
    });
  },
  getMelhorEnvioEnabledIntegration({ rootGetters, commit }, fields) {
    return api
      .get(
        `/integration/melhor-envio?construpUserId=${fields.construpUserId}`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.getToken}`,
          },
        }
      )
      .then((response) => {
        const { data } = response.data || {};

        commit("setMelhorEnvioEnabledIntegration", data);

        return response.data || {};
      });
  },
  getMelhorEnvioShipmentTracking({ rootGetters }, fields) {
    return api.post(`/integration/melhor-envio/shipment-tracking`, fields, {
      headers: {
        Authorization: `Bearer ${rootGetters.getToken}`,
      },
    });
  },
};
