import api from "@/config/api";

export default {
  create(
    _,
    {
      construpUserId,
      email,
      password,
      name,
      lastName,
      fantasyName,
      companyName,
      cnpj,
      cpf,
      rg,
      professionalType,
      phone,
      whatsapp
    }
  ) {
    return api.post(`professional/create`, {
      construpUserId,
      email,
      password,
      name,
      lastName,
      fantasyName,
      companyName,
      cnpj,
      cpf,
      rg,
      professionalType,
      phone,
      whatsapp
    });
  },
  update(
    _,
    {
      id,
      construpUserId,
      name,
      lastName,
      fantasyName,
      companyName,
      cnpj,
      cpf,
      rg,
      type,
      services,
      profilePicture,
    }
  ) {
    return api.put(`professional`, {
      id,
      construpUserId,
      name,
      lastName,
      fantasyName,
      companyName,
      cnpj,
      cpf,
      rg,
      type,
      services,
      profilePicture,
    });
  },
  requestApproval(_, { userId, professionalId, services }) {
    return api.post(`professional/request-approval`, {
      userId,
      professionalId,
      services,
    });
  },
  checkApprovementStatus({ commit }, { userId, professionalId }) {
    return api
      .get(
        `professional/request-approval/status?userId=${userId}&professionalId=${professionalId}`
      )
      .then((response) => {
        const { currentStatus } = response.data.data || {};
        commit("setApprovementStatus", currentStatus);

        return response.data.data || {};
      });
  },
  professionalListApproval(_, { construpUserId, status, currentPage }) {
    return api.get(
      `professional/request-approval/list?userId=${construpUserId}&status=${status}&page=${currentPage}`
    );
  },
  getProfessional(_, { construpUserId, professionalId }) {
    return api.get(
      `professional?construpUserId=${construpUserId}&professionalId=${professionalId}`
    );
  },
  getProfessionalData(_, { construpUserId, professionalId }) {
    return api.get(
      `professional/data?construpUserId=${construpUserId}&professionalId=${professionalId}`
    );
  },
  professionalGuideList(_, { construpUserId, services, currentPage }) {
    return api.get(
      `professional/guide/list?construpUserId=${construpUserId}&services=${services}&page=${currentPage}`
    );
  },
  updateWorkPhotos(_, { id, construpUserId, works }) {
    return api.put(`professional/work-photos`, {
      id,
      construpUserId,
      works,
    });
  },
};
