import api from '@/config/api';

/* eslint-disable */
export default {
  postGenerateOrdem({}, data) {
    return api.post('/payment/payment-ordem', data);
  },
  getItensPayment({}, id) {
    return api.get(`/payment/by-payment-ordem/${id}`);
  },
  getOrcamento({}, id) {
    return api.get(`/balconista/meus-orcamentos/${id}`);
  },
  newPaymentIugu({}, data) {
    return api.post('/payment/new-payment-iugu', data);
  },
  confirmPaymentIugu({}, data) {
    return api.post('/payment/confirm-payment-iugu', data);
  },
  showPaymentId({}, data) {
    return api.post('/payment/show-payment-iugu-id', data);
  },
  getPedido({}, id) {
    return api.post(`/payment/get-pedidos/${id}`);
  },
  getPedidoId({}, id) {
    return api.post(`/payment/get-pedido-id/${id}`);
  },
  getPaymentCredit({ commit }, construpUserId){
    return api.get(`/credit-payment/all/${construpUserId}`)
      .then((response) => {
        const { data } = response.data || {};

        commit('setCreditCardPayments', data || []);

        return response.data || {};
      });
  },
  getPaymentWallet({}, data){
    return api.get(`/payment-wallet/user/${data.construpUserId}/${data.userId}`);
  },
  historyPaymentWallet({}, id){
    return api.get(`/payment-wallet/history/${id}`);
  },
  newPaymentWallet({}, data){
    return api.post('/payment-wallet/purchase', data);
  },
  getTimePayment({}, id) {
    return api.get(`/payment/get-time-payment/${id}`);
  },
  realizarPagamento({ }, data) {
    return api.post('balconista/realizar-pagamento-balconista', data,)
  },
  getEnabledPaymentMethods({ commit }, construpUserId){
    return api.get(`/payment/methods/enabled/${construpUserId}`)
      .then((response) => {
        const { data } = response.data || {};

        commit('setEnabledPaymentMethods', data || []);

        return response.data || {};
      });
  },
};
