import { createApp } from "vue";
import Maska from "maska";
import { IonicVue } from "@ionic/vue";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import Countdown from 'vue3-flip-countdown';
import App from "./App.vue";
import router from "./router";
import store from "./vuex/store";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Bootstrap utilities */
// import "./assets/css/bootstrap-grid.min.css";
import "./assets/css/utils/index.css";

import MenuToggle from "./components/Base/MenuToggle.vue";
import LoadingComponent from "./components/LoadingComponent.vue";
import BaseLayout from "./components/Base/BaseLayout.vue";
import BaseImage from "./components/Base/BaseImage.vue";
import BaseButton from "./components/Base/BaseButton.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
import NoContent from "./components/NoContent.vue";
import VuePaycard from "vue-paycard";
// import VueAnalyticsFacebookPixel from 'vue-analytics-facebook-pixel'

configureApp();

async function createVueApp() {
  const app = createApp(App).use(IonicVue).use(router).use(store);

  app.use(Maska);
  app.use(Countdown);
  app.use(VuePaycard);

  // const pixel = localStorage.getItem("pixelApp");
  // if(pixel != undefined && pixel != '' && pixel != null) {
  //   app.use(VueAnalyticsFacebookPixel, {
  //     id: pixel
  //   })
  // }

  app.component("MenuToggle", MenuToggle);
  app.component("LoadingComponent", LoadingComponent);
  app.component("BaseLayout", BaseLayout);
  app.component("BaseImage", BaseImage);
  app.component("BaseButton", BaseButton);
  app.component("ErrorMessage", ErrorMessage);
  app.component("NoContent", NoContent);

  router
    .isReady()
    .then(() => {
      app.mount("#app");
    })
    .then(() => defineCustomElements(window));

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const lojistaId = params.get('lojista_id');

    localStorage.setItem("appBuildLojistaId", lojistaId);

  store.commit("general/setConstrupUserId", lojistaId);
}

async function configureApp() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const lojistaId = params.get('lojista_id');

  await store
    .dispatch("aboutCompany/listAboutCompany", lojistaId)
    .then((response) => {

      const { pixel_app } = response;
      if(pixel_app != null && pixel_app != '') {
        localStorage.setItem("pixelApp", pixel_app);        
      }

      configureCustomizations();
      const { logo } = response;

      const iconeHeaderText = {
        size: "15px",
        color: "#ff7f00",
      };
      localStorage.setItem("iconeHeaderText", JSON.stringify(iconeHeaderText));

      const titleHeader = {
        size: "18px",
        color: "#222",
        weight: "bold",
      };
      localStorage.setItem("titleHeader", JSON.stringify(titleHeader));

      const img = {
        logo: `https://cms.construp.com.br/storage/upload/${logo}`,
        width: "40%",
      };
      localStorage.setItem("img", JSON.stringify(img));

      const header = {
        background: "#FFF",
      };
      localStorage.setItem("header", JSON.stringify(header));
    })
    .finally(() => createVueApp());
}

async function configureCustomizations() {

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const lojistaId = params.get('lojista_id');

  await store
    .dispatch(
      "customization/getAllCustomizations",
      lojistaId
    )
    .then((response) => {
      store.commit(
        "customization/setAllCustomizations",
        response.data.data || {}
      );
    })
    .finally(() => {});
}
