
export default [
  {
    path: '/meus-pedidos',
    name: 'my-purchase',
    component: () => import('../../views/purchases/PurchasesHome.vue')
  },
  {
    path: '/meus-orcamentos',
    name: 'meus-orcamentos',
    component: () => import('../../views/purchases/MeusOrcamentos.vue')
  },
  {
    path: '/detalhes-pedido/:id',
    name: 'details-purchase',
    component: () => import('../../views/purchases/PurchasesDetails.vue')
  }
];
  