// import UserTypes from '../../enums/UserTypes';

export default [
  {
    path: "/detalhes-produto/:id",
    name: "details-product",
    component: () => import("../../views/product/ProductDetails.vue"),
  },
  {
    path: "/list-sub-category/1/:id",
    name: "layout1",
    component: () => import("../../views/product/ProductListLayout1.vue"),
  },

  {
    path: "/product-bar-code",
    name: "barcodeScanner",
    component: () => import("../../components/Product/ProductBarCode.vue"),
  },

  {
    path: "/list-sub-category/2/:id",
    name: "layout2",
    component: () => import("../../views/product/ProductListLayout2.vue"),
  },
  {
    path: "/list-sub-category/3/:id",
    name: "layout3",
    component: () => import("../../views/product/ProductListLayout3.vue"),
  },
  {
    path: "/list-sub-category/4/:id",
    name: "layout4",
    component: () => import("../../views/product/ProductListLayout4.vue"),
  },
  {
    path: "/list-product/1/:id",
    name: "list-product",
    component: () => import("../../views/product/ListProductDetails1.vue"),
  },
  {
    path: "/list-product-search",
    name: "list-product-search",
    component: () => import("../../views/product/ListProductSearch.vue"),
    params: {
      textToSearch: "",
      base64Image: "",
    },
  },
  {
    path: "/list-product/2/:id",
    name: "list-product-2",
    component: () => import("../../views/product/ListProductDetails2.vue"),
  },
  {
    path: "/list-product/4/:id",
    name: "list-product-4",
    component: () => import("../../views/product/ListProductDetails4.vue"),
  },
  {
    path: "/card-list-product/4/:id",
    name: "card-list-product-4",
    component: () => import("../../views/product/CardProductList4.vue"),
  },
  {
    path: "/show-product/3/:familia/:coluna/:sku",
    name: "show-product-3",
    component: () => import("../../views/product/ShowProduct3.vue"),
  },
  {
    path: "/show-product-list/1/:familia",
    name: "show-product-list-1",
    component: () => import("../../views/product/ShowListProduct1.vue"),
  },
  {
    path: "/listar-departamento/:id",
    name: "listarDepartamento",
    component: () => import("../../views/product/ListarDepartamentos.vue"),
  },
  {
    path: "/listar-produtos/:id",
    name: "listarProdutos",
    component: () => import("../../views/product/CardProductList.vue"),
  },
  {
    path: "/detalhes-produtos/:id",
    name: "detalhesProdutos",
    component: () => import("../../views/product/ProductDetailsWithSlider.vue"),
  },
];
