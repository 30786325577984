<template>
  <ion-app>
    <div v-if="block == false">
      <ion-split-pane content-id="main-content">
        <ion-menu
          content-id="main-content"
          type="overlay"
          :swipe-gesture="false"
        >
          <ion-content>
            <menu-itens></menu-itens>
          </ion-content>
        </ion-menu>
        <!--
          As linhas abaixo são um workaround para o componente da tela home
          não dar unmount e recarregar toda a vez que voltava a tela inicial, ficando
          muito lento a navegação.
          NÃO FAZER ISSO PARA MAIS NENHUMA TELA!!!
        -->
        <ion-router-outlet
          v-if="route.name !== 'home'"
          :key="route.fullPath"
          id="main-content"
        >
        </ion-router-outlet>
        <ion-content v-show="route.name === 'home'" id="main-content">
          <Home />
        </ion-content>
      </ion-split-pane>
    </div>
    <div v-else>
      <block-component></block-component>
    </div>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import MenuItens from "./components/Base/MenuItens.vue";
import BlockComponent from "./components/BlockComponent.vue";
import Home from "./views/Home.vue";
import { StatusBar as CapacitorStatusBar } from "@capacitor/status-bar";
import { StatusBar as CordovaStatusBar } from "@awesome-cordova-plugins/status-bar";

import useGeolocation from "Composition/useGeolocation.js";
import { App } from "@capacitor/app";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    MenuItens,
    BlockComponent,
    Home,
  },
  data() {
    return {
      user: {},
      usingStateFromShopkeeper: false,
      block: false,
    };
  },
  setup() {
    const { checkPermission, handleLocationSave } = useGeolocation();
    const route = useRoute();

    return { checkPermission, handleLocationSave, route };
  },
  computed: {
    ...mapGetters("customization", [
      "getMenuHeaderColor",
      "getIconColor",
      "getBackgroundColor",
      "getVersionBlock",
    ]),
    ...mapGetters("general", ["getConstrupUserId", "getLoggedUser"]),
    ...mapGetters("geolocation", ["getCurrentGeolocation"]),
  },
  watch: {
    getMenuHeaderColor() {
      this.changeMenuHeaderColor();
      CapacitorStatusBar.setOverlaysWebView({ overlay: false });
      CapacitorStatusBar.setBackgroundColor({ color: this.getMenuHeaderColor });
      CordovaStatusBar.overlaysWebView(false);
      CordovaStatusBar.backgroundColorByHexString(this.getMenuHeaderColor);
      // document.body.style.backgroundColor = this.getMenuHeaderColor;
    },
    getIconColor() {
      this.changeIconsColor();
    },
    getBackgroundColor() {
      // this.changeBackgroundColor();
    },
    "getCurrentGeolocation.state": function () {
      this.handleSavedGeolocation();
    },
    "getVersionBlock.state": function () {
      this.getVersion();
    },
  },
  created() {
    this.addDomChangeObserver();
    this.handleSavedGeolocation();
    this.validItensCart();
    this.getVersion();

    this.user = JSON.parse(localStorage.getItem("user"));
  },
  async mounted() {
    await this.validateCurrentGeolocation();

    if (this.getLoggedUser.user_id) {
      this.listNotification({
        construpUserId: this.getConstrupUserId,
        userId: this.getLoggedUser.user_id,
      });
    }

    this.changeIconsColor();
    this.changeMenuHeaderColor();

    const iconeHeader = {
      size: "25px",
      color: this.getIconColor,
    };
    localStorage.setItem("iconeHeader", JSON.stringify(iconeHeader));

    // comentado para não pedir a localização logo que aberto o app
    // if (this.usingStateFromShopkeeper) {
    //   await this.checkPermission();
    // }

    this.getPaymentCredit(this.getConstrupUserId);
    this.getFreight(this.getConstrupUserId);
    this.getAllSocialMedias(this.getConstrupUserId);
    this.getEnabledPaymentMethods(this.getConstrupUserId);
  },
  methods: {
    ...mapActions("notification", ["listNotification"]),
    ...mapActions("geolocation", ["handleSavedGeolocation"]),
    ...mapActions("general", ["getAddressShopkeeper"]),
    ...mapActions("payment", ["getPaymentCredit", "getEnabledPaymentMethods"]),
    ...mapActions("freight", ["getFreight"]),
    ...mapActions("socialMedias", ["getAllSocialMedias"]),
    getVersion() {
      if (process.env.VUE_APP_MOBILE != "web") {
        App.getInfo().then((obj) => {
          if (
            this.getVersionBlock != null &&
            obj.build <= this.getVersionBlock
          ) {
            this.block = true;
          }
        });
      }
    },
    validItensCart() {
      const cart = JSON.parse(localStorage.getItem("validItensCart"));

      if (cart != true) {
        localStorage.removeItem("cartItens");
        localStorage.setItem("validItensCart", true);
      }
    },
    addDomChangeObserver() {
      const observer = new (window.MutationObserver ||
        window.WebKitMutationObserver)(() => {
        this.changeIconsColor();
        this.changeMenuHeaderColor();
        this.changeBackgroundColor();
      });

      observer.observe(document, {
        subtree: true,
        childList: true,
      });
    },
    changeIconsColor() {
      const icons = document.querySelectorAll("ion-icon");

      icons.forEach((x) => {
        const colorIcon = x.shadowRoot.querySelector(".icon-inner");
        if (!colorIcon) return;
        colorIcon.style.color = this.getFontColor;
      });
    },
    changeMenuHeaderColor() {
      const toolbars = document.querySelectorAll("ion-toolbar");

      toolbars.forEach((x) => {
        if (x.classList && x.classList.contains("no-change-color")) return;

        const content = x.shadowRoot.querySelector(".toolbar-content");

        if (!content) return;

        content.style.backgroundColor = this.getMenuHeaderColor;
      });
    },
    changeBackgroundColor() {
      const ionContents = document.querySelectorAll("ion-content");

      ionContents.forEach((x) => {
        const content = x.shadowRoot.querySelector("#background-content");

        if (!content) return;

        content.style.backgroundColor = `${this.getBackgroundColor}`;
      });
    },
    async validateCurrentGeolocation() {
      if (this.getCurrentGeolocation.cep) return;

      const response = await this.getAddressShopkeeper(this.getConstrupUserId);

      const { cep, uf, nome, bairro, endereco } = response.data || {};

      this.usingStateFromShopkeeper = true;

      this.handleLocationSave({
        cep: String(cep),
        state: uf,
        stateName: "",
        cityName: nome,
        latitude: 0,
        longitude: 0,
        fullAddress: `${endereco}, ${bairro}, ${nome} - ${uf}, ${cep}`,
        fromShopkeeper: true,
      });
    },
  },
});
</script>

<style scoped>
::v-deep .list-ios,
::v-deep .list-md {
  background: transparent;
}

::v-deep ion-toolbar > .toolbar-container {
  padding-inline-start: unset;
  padding-inline-end: unset;
  padding-top: unset;
  padding-bottom: unset;
}
</style>