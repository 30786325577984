<template>
  <ion-slides
    :key="banners.map((banner) => banner.bannerId).join('_')"
    :options="slideOpts"
    pager
  >
    <ion-slide v-for="(banner, index) in banners" :key="index">
      <a :href="banner.url || '#'" target="_blank">
        <img
          :src="`https://cms.construp.com.br/storage/upload/${
            banner.image || ''
          }`"
        />
      </a>
    </ion-slide>
  </ion-slides>
</template>

<script>
import { IonSlides, IonSlide, IonImg } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { ref } from "vue";
import BANNER_PLATFORMS from '@/enums/BannerPlatforms.js';

export default {
  name: "Banner",
  components: {
    IonSlides,
    IonSlide,
    IonImg,
  },
  setup() {
    const loading = ref(false);

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      roundLengths: true,
    };

    const banners = ref([]);

    return {
      slideOpts,
      banners,
      loading,
    };
  },
  data() {
    return {
      BANNER_PLATFORMS,
    }
  },
  computed: {
    ...mapGetters("general", ["getConstrupUserId"]),
    ...mapGetters("banner", ["getBannersHome"]),
  },
  created() {
    if (!this.getBannersHome || !this.getBannersHome.length) this.showBanners();
    else this.handleBannersUserTypes(this.getBannersHome || []);
  },
  methods: {
    ...mapActions("banner", ["getBanners"]),
    async showBanners() {
      this.loading = true;
      this.getBanners(this.getConstrupUserId)
        .then((response) => {
          let result = response.data.data || [];
          this.handleBannersUserTypes(result);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleBannersUserTypes(banners) {
      const user = JSON.parse(localStorage.getItem("user"));

      banners.forEach((element) => {
        if(!element.platforms[0] ||  parseInt(element.platforms[0].platform) !== BANNER_PLATFORMS.WEB) {
          element.userTypes.forEach((type) => {
            if (!user && type.user_type == 1) this.banners.push(element);
            if (user != null && type.user_type == user.type)
              this.banners.push(element);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
ion-slides {
  --bullet-background: rgba(0, 0, 0, 1);
  /* --bullet-background-active: black; */
}
</style>
