import { createStore } from "vuex";

import user from "./modules/user/index";
import client from "./modules/client/index";
import product from "./modules/product/index";
import customization from "./modules/customization/index";
import socialMedias from "./modules/socialMedias/index";
import aboutCompany from "./modules/aboutCompany/index";
import general from "./modules/general/index";
import banner from "./modules/banner/index";
import payment from "./modules/payment/index";
import blog from "./modules/blog/index";
import notification from "./modules/notification/index";
import professional from "./modules/professional/index";
import files from "./modules/files/index";
import freight from "./modules/freight/index";
import geolocation from "./modules/geolocation/index";
import empresa from "./modules/empresa/index";
import integration from "./modules/integration/index";

const store = createStore({
  modules: {
    user,
    client,
    product,
    customization,
    socialMedias,
    aboutCompany,
    general,
    banner,
    payment,
    blog,
    notification,
    professional,
    files,
    freight,
    geolocation,
    empresa,
    integration,
  },
});

export default store;
