import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

export default function () {
  async function takePhoto({
    resultType = CameraResultType.Base64,
    source = CameraSource.Prompt,
    quality = 100,
    allowEditing = true,
  }) {
    const cameraPhoto = await Camera.getPhoto({
      resultType,
      source,
      quality,
      allowEditing,
      presentationStyle: "fullscreen",
      promptLabelHeader: "Foto",
      promptLabelCancel: "Cancelar",
      promptLabelPhoto: "Galeria",
      promptLabelPicture: "Tirar foto",
    });

    let img = cameraPhoto.base64String;

    return "data:image/jpeg;base64," + img;
  }
  return {
    takePhoto,
  };
}
