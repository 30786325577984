export default {
  getConstrupUserId(state) {
    return state.construpUserId;
  },
  getLoggedUser() {
    return JSON.parse(localStorage.getItem("user") || null) || {};
  },
  getCities(state) {
    return state.cities;
  },
  getIbgeCities(state) {
    return state.ibgeCities;
  },
  getSegments(state) {
    return state.segments;
  },
  getTypeProfessions(state) {
    return state.typeProfessions;
  },
//   getImagesAddress() {
//     return process.env.VUE_APP_IMAGES_ADDRESS;
//   },
//   getApiUrl() {
//     return process.env.VUE_APP_API_URL;
//   },
//   getWhatsappTelephone() {
//     return process.env.VUE_APP_WHATS_TELEPHONE;
//   },
//   getSmallLogoAddress() {
//     return process.env.VUE_APP_SMALL_LOGO_ADDRESS;
//   },
//   getLargeLogoAddress() {
//     return process.env.VUE_APP_LARGE_LOGO_ADDRESS;
//   },
//   getContactEmail() {
//     return process.env.VUE_APP_CONTACT_EMAIL;
//   },
};
