import { toastController } from '@ionic/vue';

export default function () {
  async function openToast(message, color, position = 'bottom', duration = 3000) {
    const toast = await toastController.create({
      message,
      position,
      color,
      duration,
    });

    return toast.present();
  }

  return {
    openToast,
  };
}
