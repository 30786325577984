import { createRouter, createWebHistory } from "@ionic/vue-router";

import client from "./client/index";
import cart from "./cart/index";
import product from "./product/index";
import payment from "./payment/index";
import purchase from "./purchase/index";
import blog from "./blog";
import professional from "./professional/index.js";
import professionalGuide from "./professionalGuide.js";
import empresa from "./empresa/index.js";
import premio from "./premio/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { valid: "login" },
    component: () => import("@/views/BaseRegister.vue"),
  },
  {
    path: "/favorite",
    name: "favorite",
    component: () => import("@/views/cart/Favorite.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { valid: "login" },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/sair",
    name: "logout",
    component: () => import("@/views/Logout.vue"),
  },
  {
    path: "/rescue-password",
    name: "rescue-password",
    component: () => import("@/views/RescuePassword.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contact/Index.vue"),
  },
  {
    path: "/chatgpt",
    name: "chat-gpt",
    component: () => import("@/views/chatGpt/Index.vue"),
  },
  ...client,
  ...cart,
  ...product,
  ...payment,
  ...purchase,
  ...blog,
  ...professional,
  ...professionalGuide,
  ...empresa,
  ...premio
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
