import api from '@/config/api';

/* eslint-disable */
export default {
  getProductCategoryAll({}, id) {
    return api.get(`/product/category/${id}`);
  },
  pushSubMenuId({}, data) {
    return api.post('/product/sub-menu', data);
  },
  pushSelectItem({}, data) {
    return api.post('/product/push-product-itens', data);
  },
  getSubDeparts({}, id) {
    return api.get(`/product/get-sub-departs/${id}`);
  },
  getSubDepartamentos({}, data) {
    return api.get(`/product/get-sub-departamentos/${data.lojistaId}/${data.departamento_id}`);
  },
  pushSubMenuCategory3({}, id) {
    return api.get(`/product/get-departs-table/${id}`);
  },
  showProduct({}, params) {
    return api.get(`/product/list-products/${params.familia}/${params.coluna}/${params.sku}/${params.lojista}`);
  },
  detalhesProdutos({}, params) {
    return api.get(`/product/listar-produtos/${params.id}/${params.lojista}?state=${params.state}&userType=${params.userType}`);
  },
  getListProducts({}, data) {
    return api.get(`/product/get-products-table/${data.lojistaId}/${data.departamento_id}?page=${data.currentPage || 1}&state=${data.state}`);
  },
  getListProductsDep1({}, data) {
    return api.get(`/product/products-dep-1/${data.id}/${data.lojista}`);
  },
  openBarCode({}, data) {
    return api.get( `/product/show-by-products/${data.code}/${data.construpUserId}` );
  },
  pushPriceCart({}, itens) {
    return api.post(`/product/list-cart-itens`, itens);
  },
  pushPriceBudget({}, id) {
    return api.get(`/balconista/list-cart-budget/${id}`);
  },
  getProductsHome({ commit }, { id, state = '', userType }) {
    return api.get(`/product/show-all-products-home/${id}?state=${state}&userType=${userType}`)
      .then((response) => {
        commit('setProductsHome', (response.data || {}).data || []);
        return response;
      });
  },
  searchProducts({}, { text, currentPage, construpUserId, description }) {
    return api.post(`/product/search-product?page=${currentPage}`, { text, construpUserId, description });
  },
  produtoIndisponivel({}, data) {
    return api.post('/produto-indisponivel', data);
  },
  verificarProdutoIndisponivel({}, data) {
    return api.get(`/verificar-produto-indisponivel/${data.idUser}/${data.idProduct}`);
  },
  byImageRecognition({}, { base64 }) {
    return api.post('/product/image-recognition', { base64 });
  },
};
