import Axios from "axios";
import useGeolocation from "./useGeolocation";

export default function (importGeolocation = true) {
  let geolocation = null;

  if (importGeolocation)
    geolocation = useGeolocation();

  function cepIsValid(cep) {
    return cep && (cep.length === 8 || cep.length === 9);
  }

  /**
   * @param {string} cep
   * @returns
   */
  async function getCityByCep(cepToConsult, handleLocationSave = true) {
    if (!cepIsValid(cepToConsult)) return {};

    const handledCep = cepToConsult.replace("-", "");
    let response = null;

    try {
      response = await Axios.get(
        `https://viacep.com.br/ws/${handledCep}/json/`
      );
    } catch {
      return {
        invalid: true,
      };
    }

    if (response.data.erro) {
      return {
        invalid: true,
      };
    }

    const {
      cep,
      logradouro,
      complemento,
      bairro,
      localidade,
      uf,
      ibge,
      gia,
      ddd,
      siafi,
    } = response.data || {};

    let fullAddress = "";

    if (logradouro) fullAddress += `${logradouro}, `;
    if (bairro) fullAddress += `${bairro}, `;
    if (localidade) fullAddress += `${localidade} - `;
    if (uf) fullAddress += `${uf}, `;
    if (cep) fullAddress += `${cep}`;

    const data = {
      cep: cep,
      state: uf,
      stateName: "",
      cityName: localidade,
      latitude: 0,
      longitude: 0,
      street: logradouro,
      district: bairro,
      fullAddress,
    };

    if (handleLocationSave) {
      geolocation.handleLocationSave(data);
    }

    return data;
  }

  return {
    getCityByCep,
    cepIsValid,
  };
}
