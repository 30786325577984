import ENABLED_INTEGRATION_STATUS from "Enums/integration/enabledIntegrationStatus";

export default {
  getMelhorEnvioEnabledIntegration(state) {
    return state.melhorEnvioEnabledIntegration;
  },
  isMelhorEnvioEnabled(state) {
    return (
      (state.melhorEnvioEnabledIntegration || {}).status ===
      ENABLED_INTEGRATION_STATUS.ACTIVE
    );
  },
};
