<template>
  <ion-img
    :src="!imageFailsToLoad ? `http://api.construp.com.br/storage/upload/${src}` : defaultImage"
    @ionError="imageFailsToLoad = true"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
// import { mapGetters } from "vuex";

export default {
  name: "BaseImage",
  props: {
    /** O caminho da imagem */
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    // ...mapGetters("general", ["getImagesAddress"]),
  },
  setup() {
    const imageFailsToLoad = ref(false);

    return {
      imageFailsToLoad,
      defaultImage: "assets/vectors/blank.svg",
    };
  },
};
</script>
