<template>
  <div>
    <div class="menuClose">
      <ion-menu-toggle>
        <ion-icon
          :icon="Icon.close"
          :style="`color: ${getIconColor}`"
        ></ion-icon>
      </ion-menu-toggle>
    </div>
    <ion-toolbar :style="`--background: ${getMenuHeaderColor}`">
      <div :class="device ? 'menuIosHeader' : 'menuCardHeader'">
        <ion-col size="2">
          <ion-icon
            :icon="Icon.person"
            :style="`width: 50px;height: 50px;color: ${getIconColor}`"
          ></ion-icon>
        </ion-col>
        <ion-col size="9">
          <span
            class="menuTitleHeader"
            v-if="user.first_name != ''"
            :style="`color: ${getIconColor}`"
            >Bem-vindo {{ user.first_name }}</span
          >
          <span
            class="menuTitleHeader ml-3"
            v-else
            :style="`color: ${getIconColor}`"
            >Faça seu login ou se cadastre</span
          >
        </ion-col>
      </div>
      <div
        class="cardLogin"
        @click="nextUrl(user.first_name != '' ? 'logout' : 'login')"
        :style="`border: 2px solid ${getIconColor}`"
      >
        <ion-menu-toggle class="CardLoginToggle">
          <ion-icon
            :icon="Icon.enterOutline"
            class="cardLoginIcon"
            :style="`color: ${getIconColor}`"
          ></ion-icon>
          <span :style="`color: ${getIconColor}`">{{
            user.first_name != "" ? "Sair" : "Entrar"
          }}</span>
        </ion-menu-toggle>
      </div>
    </ion-toolbar>
    <ion-list>
      <div class="menuOption" @click="nextUrl('home')">
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.home"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span
            class="menuOptionText"
            :style="`color: ${getFontColor}`">
            Início
          </span>
        </ion-menu-toggle>
      </div>
      <div
        v-if="user.first_name != '' && user.type != 3"
        class="menuOption"
        @click="
          nextUrl(
            user.type === USER_TYPES.CLIENT
              ? 'clients-data'
              : 'professional-data'
          )
        "
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.person"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Minha conta</span
          >
        </ion-menu-toggle>
      </div>
      <div
        v-if="user.first_name != '' && user.type == 3"
        class="menuOption"
        @click="nextUrl('empresa-data')"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.person"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Minha conta</span
          >
        </ion-menu-toggle>
      </div>

      <div
        v-if="user.first_name != ''"
        class="menuOption"
        @click="nextUrl('my-purchase')"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.bag"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Meus pedidos</span
          >
        </ion-menu-toggle>
      </div>

      <div
        v-if="user.first_name != '' && getRegraPontos == true"
        class="menuOption"
        @click="nextUrl('meus-pontos')"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.pricetag"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Meus pontos</span
          >
        </ion-menu-toggle>
      </div>

      <div
        v-if="user.first_name != ''"
        class="menuOption"
        @click="nextUrl('meus-orcamentos')"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.paperPlane"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Orçamentos</span
          >
        </ion-menu-toggle>
      </div>

      <div v-if="user.first_name != ''">
        <div class="menuOption" @click="nextUrl('favorite')">
          <ion-menu-toggle>
            <ion-icon
              :icon="Icon.heart"
              class="menuOptionIcon"
              :style="`color: ${getFontColor}`"
            ></ion-icon>
            <span class="menuOptionText" :style="`color: ${getFontColor}`"
              >Favoritos</span
            >
          </ion-menu-toggle>
        </div>
      </div>
      <div
        v-if="user.first_name != ''"
        class="menuOption"
        @click="nextUrl('clients-cards')"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.card"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Meus cartões</span
          >
        </ion-menu-toggle>
      </div>

      <div v-if="user.first_name != '' ? (logged = true) : (logged = false)">
        <div class="menuOption" @click="nextUrl('clients-address')">
          <ion-menu-toggle>
            <ion-icon
              :icon="Icon.locationSharp"
              class="menuOptionIcon"
              :style="`color: ${getFontColor}`"
            ></ion-icon>
            <span class="menuOptionText" :style="`color: ${getFontColor}`"
              >Endereços</span
            >
          </ion-menu-toggle>
        </div>
      </div>

      <div v-if="user.first_name != '' ? (logged = true) : (logged = false)">
        <div
          class="menuOption"
          @click="nextUrl('clients-wallet')"
          v-if="isClientWallet == true"
        >
          <ion-menu-toggle>
            <ion-icon
              :icon="Icon.walletSharp"
              class="menuOptionIcon"
              :style="`color: ${getFontColor}`"
            ></ion-icon>
            <span class="menuOptionText" :style="`color: ${getFontColor}`"
              >Minha carteira</span
            >
          </ion-menu-toggle>
        </div>
      </div>

      <div
        class="menuOption"
        @click="(showCategory = !showCategory), (expand = '')"
      >
        <ion-icon
          :icon="Icon.list"
          class="menuOptionIcon"
          :style="`color: ${getFontColor}`"
        ></ion-icon>
        <span class="menuOptionText" :style="`color: ${getFontColor}`"
          >Categorias</span
        >
        <ion-icon
          :icon="showCategory ? Icon.chevronUpOutline : Icon.chevronDownOutline"
          class="menuCategoryIcon"
          :style="`color: ${getFontColor}`"
        ></ion-icon>
      </div>
      <div
        v-if="
          showCategory &&
          fixadores &&
          fixadores[0] &&
          fixadores[0].relacao_departamentos &&
          fixadores[0].relacao_departamentos.length > 0
        "
      >
        <div v-for="(category, i) in fixadores" :key="i">
          <div
            class="menuOption"
            @click="expandCategory(category.nome_departamento)"
          >
            <img
              :src="
                category.imagem_departamento
                  ? showImg(category.imagem_departamento)
                  : 'https://storage.googleapis.com/fotos-prd/FOTOS%20MENU/1589546374647fixadores.png'
              "
              class="imgCategory"
              :alt="category.departamentoNome"
            />
            <span
              class="menuOptionTextCategory"
              :style="`color: ${getFontColor}`"
              >{{ category.nome_departamento }}</span
            >
            <ion-icon
              :icon="
                expand == category.nome_departamento
                  ? Icon.removeOutline
                  : Icon.addOutline
              "
              class="menuCategoryIcon"
              :style="`color: ${getFontColor}`"
            ></ion-icon>
          </div>
          <div v-for="(depart, i) in category.relacao_departamentos" :key="i">
            <div
              class="menuOptionCategory"
              v-if="expand == depart.nome_departamento_pai"
              @click="showSubMenuBelenus(depart)"
            >
              <ion-menu-toggle>
                <p
                  class="menuOptionTextCategory"
                  :style="`color: ${getFontColor}`"
                >
                  {{ depart.nome_departamento }}
                </p>
              </ion-menu-toggle>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showCategory && categories && categories.length > 0">
        <div v-for="(category, i) in categories" :key="i">
          <div
            class="menuOption"
            @click="expandCategory(category.nome_departamento)"
          >
            <img
              :src="
                category.imagem_departamento
                  ? showImg(category.imagem_departamento)
                  : 'https://storage.googleapis.com/fotos-prd/FOTOS%20MENU/1589546374647fixadores.png'
              "
              class="imgCategory"
              :alt="category.departamentoNome"
            />
            <span
              class="menuOptionTextCategory"
              :style="`color: ${getFontColor}`"
              >{{ category.nome_departamento }}</span
            >
            <ion-icon
              :icon="
                expand == category.nome_departamento
                  ? Icon.removeOutline
                  : Icon.addOutline
              "
              class="menuCategoryIcon"
              :style="`color: ${getFontColor}`"
            ></ion-icon>
          </div>
          <div v-for="(depart, i) in category.relacao_departamentos" :key="i">
            <div
              class="menuOptionCategory"
              v-if="expand == depart.nome_departamento_pai"
              @click="showSubMenu(depart)"
            >
              <ion-menu-toggle>
                <p
                  class="menuOptionTextCategory"
                  :style="`color: ${getFontColor}`"
                >
                  {{ depart.nome_departamento }}
                </p>
              </ion-menu-toggle>
            </div>
          </div>
        </div>
      </div>

      <div v-if="user.first_name != '' ? (logged = true) : (logged = false)">
        <div
          class="menuOption ion-align-items-center"
          v-show="logged"
          style="display: flex"
          @click="nextUrl('notifications')"
        >
          <ion-menu-toggle>
            <ion-icon
              :icon="Icon.notifications"
              class="menuOptionIcon"
              :style="`color: ${getFontColor}`"
            ></ion-icon>
            <span class="menuOptionText" :style="`color: ${getFontColor}`"
              >Notificações
            </span>
            <ion-badge color="dark" style="margin-left: 12px">{{
              getNotificationsQuantity
            }}</ion-badge>
          </ion-menu-toggle>
        </div>
      </div>

      <div class="menuOption" @click="nextUrl('contact')">
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.mailOpen"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Contato</span
          >
        </ion-menu-toggle>
      </div>
      <div class="menuOption" @click="nextUrl('blog')">
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.newspaper"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Blog</span
          >
        </ion-menu-toggle>
      </div>
      <div
        class="menuOption"
        v-if="user.first_name == ''"
        @click="nextUrl('register')"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.personAdd"
            class="menuOptionIcon sizeIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`"
            >Cadastre-se</span
          >
        </ion-menu-toggle>
      </div>
      <div class="menuOption">
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.camera"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          >
          </ion-icon>
          <span
            class="menuOptionText"
            @click="getFilesByCamera()"
            :style="`color: ${getFontColor}`"
            >Pesquisar por foto</span
          >
        </ion-menu-toggle>
      </div>
      <div
        class="menuOption"
        @click="nextUrl('request-approval')"
        v-if="user.type === USER_TYPES.PROFESSIONAL && user.first_name != ''"
      >
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.lockOpen"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`">
            Solicitar aprovação
          </span>
        </ion-menu-toggle>
      </div>
      <div v-if="haveProfissional" class="menuOption" @click="nextUrl('professional-guide')">
        <ion-menu-toggle>
          <ion-icon
            :icon="Icon.hammer"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`">
            Guia de profissionais
          </span>
        </ion-menu-toggle>
      </div>
      <!-- <div class="menuOption" @click="nextUrl('chat-gpt')">
        <ion-menu-toggle v-if="active">
          <ion-icon
            :icon="Icon.chatbubbles"
            class="menuOptionIcon"
            :style="`color: ${getFontColor}`"
          ></ion-icon>
          <span class="menuOptionText" :style="`color: ${getFontColor}`">
            Chat GPT
          </span>
        </ion-menu-toggle>
      </div> --> 
    </ion-list>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import USER_TYPES from "Enums/userTypes.js";
import usePhotoGallery from "../../composition/usePhotoGallery";
import useToast from "@/config/useToast";
import APPROVEMENT_STATUS from 'Enums/professional/approvementStatus.js';
import {
  menu,
  enterOutline,
  close,
  home,
  heart,
  locationSharp,
  person,
  personAdd,
  bag,
  card,
  list,
  notifications,
  newspaper,
  mailOpen,
  helpCircle,
  chevronDownOutline,
  chevronUpOutline,
  addOutline,
  removeOutline,
  walletSharp,
  lockOpen,
  hammer,
  paperPlane,
  pricetag,
  camera,
  chatbubbles
} from "ionicons/icons";
import {
  IonIcon,
  IonMenuToggle,
  IonCol,
  IonToolbar,
  IonBadge,
  IonList,
  menuController,
} from "@ionic/vue";
import { mapGetters } from "vuex";
import { isPlatform } from "@ionic/vue";
import { useRouter } from 'vue-router';

export default {
  name: "MenuItens",
  components: {
    IonIcon,
    IonMenuToggle,
    IonCol,
    IonToolbar,
    IonBadge,
    IonList,
    isPlatform,
  },
  data() {
    return {
      USER_TYPES,
      APPROVEMENT_STATUS,
      logged: null,
      active: true,
      lojistaId: null,
      expand: "",
      getNotificationsQuantity: null,
      categories: [],
      fixadores: [],
      haveProfissional: false,
      isClientWallet: false,
      device: false,
      user: {
        first_name: "",
        nome_fantasia: null,
        Last_name: "",
        type: "",
      },
    };
  },
  setup() {
    const showCategory = ref(false);
    const { openToast } = useToast();
    const { takePhoto } = usePhotoGallery();
    const router = useRouter();

    const Icon = ref({
      menu,
      enterOutline,
      person,
      close,
      home,
      heart,
      locationSharp,
      bag,
      card,
      list,
      notifications,
      newspaper,
      mailOpen,
      personAdd,
      helpCircle,
      chevronDownOutline,
      chevronUpOutline,
      addOutline,
      removeOutline,
      walletSharp,
      lockOpen,
      hammer,
      paperPlane,
      pricetag,
      camera,
      chatbubbles
    });

    return {
      Icon,
      showCategory,
      openToast,
      takePhoto,
      router,
    };
  },
  computed: {
    ...mapGetters("customization", [
      "getFontColor",
      "getIconColor",
      "getMenuHeaderColor",
      "getRegraPontos"
    ]),
    ...mapGetters("general", ["getConstrupUserId"]),
  },
  watch: {
    device() {},
    "$route.name": function () {
      menuController.close();

      if (this.$route.meta.valid && this.$route.meta.valid == "login") {
        this.validLogin();
      }
    },
  },
  mounted() {
    this.isApple();
    this.validLogin();
    this.getWalletClient();
    this.chatActive();
  },
  methods: {
    ...mapActions("general", ["getConfigGpt"]),
    async chatActive() {
            const response = await this.getConfigGpt();
            response.data.chatgpt == 0 ?
            this.active = false :
            this.active = true;
          },
    isApple() {
      if (isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
        this.device = true;
      }
    },
    showProfissional(){
      this.professionalListApproval({
        construpUserId: this.getConstrupUserId,
        status: APPROVEMENT_STATUS.ACCEPTED,
        currentPage: 1,
      }).then((response) => {
          const { data } = response.data || {};
          this.haveProfissional = data.total > 0 ? true : false;
        })
    },
    showImg(img) {
      return process.env.VUE_APP_FOTOS_URL_CAT + img;
    },
    validLogin() {
      this.lojistaId = this.getConstrupUserId;
      const user = JSON.parse(localStorage.getItem("user"));
      if (user != null) {
        this.user = user;
        if (this.user.nome_fantasia != null) {
          this.user.first_name = user.nome_fantasia;
        }
      } else {
        this.user.first_name = "";
      }
      this.listProductCategoryAll();
      this.getWalletClient();
    },
    getWalletClient() {
      if (this.user.user_id == undefined) {
        return;
      }
      const data = {
        construpUserId: this.lojistaId,
        userId: this.user.user_id,
      };
      this.loading = true;
      this.$store
        .dispatch("payment/getPaymentWallet", data)
        .then((response) => {
          if (response.data[0].block == 0) {
            this.isClientWallet = true;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    nextUrl(value) {
      this.router.push({ name: value });
      menuController.close();
    },
    expandCategory(category) {
      if (this.expand == category) {
        this.expand = "";
      } else {
        this.expand = category;
      }
    },
    showSubMenu(depart) {
      if (depart.link == 0) {
        this.router.push({
          name: "listarDepartamento",
          params: { id: depart.id },
        });
      } else {
        this.router.push({
          name: "listarProdutos",
          params: { id: depart.id },
        });
      }
    },
    showSubMenuBelenus(depart) {
      this.router.push({
        name: depart.tipo_layout,
        params: { id: depart.departamento_id },
      });
    },
    listProductCategoryAll() {
      this.$store
        .dispatch(
          "product/getProductCategoryAll",
          this.getConstrupUserId
        )
        .then((response) => {
          if (response.data.data) {
            this.fixadores = response.data.data.fixadores;
            this.categories = response.data.data.departamentos;
            this.searchCategory = true;
          }
        })
        .catch(() => {});
    },
    async getFilesByCamera() {
      this.searchInput(
        await this.takePhoto({ quality: 80, allowEditing: false })
      );
    },
    searchInput(base64 = "") {
      this.router.push({
        name: "list-product-search",
        params: {
          textToSearch: this.inputSearch,
          base64Image: base64 || "",
        },
      });
    },
  },
};
</script>

<style scoped>
.menuCardHeader {
  display: flex;
  padding: 15px 0;
}
.menuIosHeader {
  display: flex;
  padding: 15px 0;
  margin-top: 15px;
}
.menuClose {
  position: absolute;
  z-index: 999;
  right: 5px;
  top: 5px;
  font-size: 30px;
}
.menuTitleHeader {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 600;
}
.cardLogin {
  display: flex;
  /* background: #fff; */
  width: 70%;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 auto 15px;
}
.cardLoginIcon {
  font-size: 25px;
  margin-right: 15px;
}
.CardLoginToggle {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.imgCategory {
  width: 25px;
}
.menuOptionTextCategory {
  margin-left: 10px;
  font-size: 15px;
  position: relative;
  bottom: 5px;
}
.menuOptionCategory {
  background: #f1f1f1;
  padding-left: 5%;
  padding-right: 3%;
  padding-top: 7px;
  border-bottom: 3px solid #fff;
}
.menuOption {
  padding-left: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.menuOptionText {
  margin-left: 10px;
  font-size: 20px;
}
.menuCategoryIcon {
  position: absolute;
  right: 20px;
  font-size: 22px;
}
.menuOptionIcon {
  font-size: 20px;
  position: relative;
  top: 2px;
}
.sizeIcon {
  font-size: 20px;
  font-weight: bold;
}
</style>