<template>
  <base-layout use-refresher :loading="loading" @onRefresh="getProducts">
    <banner-component></banner-component>
    <div v-if="products != null">
      <span v-for="(item, i) in products.products" :key="i">
        <card-product-component
          :items="item"
          :timer="item.limit_time"
          :pix="products.pix"
          :bank="products.bank"
          :card="products.card"
          :generalDiscount="products.general_discount"
        ></card-product-component>
      </span>
    </div>
  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";

import BannerComponent from "@/components/Images/BannerComponent.vue";
import CardProductComponent from "@/components/Product/CardProductComponent.vue";

import { menuController } from "@ionic/vue";

export default {
  name: "HomeStart",
  components: {
    BannerComponent,
    CardProductComponent,
  },
  data() {
    return {
      loading: false,
      products: null,
    };
  },
  computed: {
    ...mapGetters("geolocation", ["getCurrentGeolocation"]),
    ...mapGetters("user", ["getUserType"]),
    ...mapGetters("product", ["getProductsHome"]),
    ...mapGetters("general", ["getConstrupUserId"]),
  },
  watch: {
    "getCurrentGeolocation.state": function () {
      this.getProducts();
    },
  },
  mounted() {
    localStorage.removeItem("rotaLogin");
    localStorage.removeItem("changeDeliveryType");
    if (!this.getProductsHome || this.getProductsHome.length === 0)
      this.getProducts();
    else this.products = this.getProductsHome || [];
  },
  methods: {
    getProducts(refresherEvent) {
      menuController.close();

      this.loading = true;

      this.$store
        .dispatch("product/getProductsHome", {
          id: this.getConstrupUserId,
          state: this.getCurrentGeolocation.state,
          userType: this.getUserType,
        })
        .then((response) => {
          this.products = response.data.data;
          this.loading = false
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;

          if (refresherEvent) {
            refresherEvent.target.complete();
          }
        });
    },
  },
};
</script>
