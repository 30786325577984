<template>
  <!-- :dismiss-on-select="true" -->
  <ion-popover
    v-show="validPopover"
    arrow
    dismiss-on-select
    keep-contents-mounted
    size="cover"
    side="bottom"
    alignment="center"
    :keyboard-close="false"
    :show-backdrop="false"
    :trigger="targetElementId"
    style="max-height: 85%;"
  >
    <ion-content color="light" scroll-y>
      <ion-list style="max-height: fit-content;">
        <ion-item v-if="loading" lines="none" class="text-center fs-12">
          Pesquisando produtos
          <ion-spinner name="dots" slot="end"></ion-spinner>
        </ion-item>
        <ion-item lines="none" v-else-if="!products.length && firstSearch" class="fs-12">
          Busque produtos por nome, marca, descrição...
        </ion-item>
        <ion-item lines="none" v-else-if="!products.length && !firstSearch" class="fs-12">
          Sem produtos encontrados
        </ion-item>
        <ion-item
          lines="none"
          v-else-if="products.length"
          v-for="product in products"
          :key="product.id"
          @click="
            $router.push({
              name: 'detalhesProdutos',
              params: { id: product.id },
            })
          "
        >
          <ion-avatar class="mr-2">
            <ion-img
              :src="
                useImageFormatUrl(
                  product.produto_fotos[0].foto_png,
                  product.origem
                )
              "
            />
          </ion-avatar>
          <span class="fs-12 text-justify">
            {{ product.produto }}
          </span>
        </ion-item>
        <ion-item
          v-if="products.length"
          button
          :detail="false"
          lines="none"
          class="fs-12"
          @click="
            $router.push({
              name: 'list-product-search',
              params: {
                textToSearch,
              },
            })
          "
        >
          Ver todos
          <ion-icon :icon="arrowForwardCircleOutline" slot="end"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-popover>
</template>

<script setup>
import {
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonAvatar,
  IonIcon,
  IonSpinner,
  IonImg
} from "@ionic/vue";

import { arrowForwardCircleOutline } from "ionicons/icons";

import { ref, defineProps, watch, toRefs } from "vue";
import { useStore } from "vuex";
import useDebounce from "@/composition/useDebounce";
import useImageFormatUrl from "@/composition/images/useFormatUrl";

const store = useStore();

let loading = ref(false);
let error = ref(false);
let products = ref([]);
let firstSearch = ref(true);

const props = defineProps({
  textToSearch: String,
  description: String,
  sizeToBeginSearch: {
    type: Number,
    default: 3,
  },
  validPopover: {
    type: Boolean,
    default: false,
  },
  targetElementId: String,
});

const { textToSearch, description, sizeToBeginSearch, targetElementId } = toRefs(props);

watch(
  textToSearch, () => {
    searchWithDebounce();
  }
);

const { getConstrupUserId } = localStorage.getItem("appBuildLojistaId");

const searchWithDebounce = useDebounce(search, 1000);

async function search() {
  if (!textToSearch.value || textToSearch.value.length < sizeToBeginSearch.value)return;

  firstSearch.value = false;
  loading.value = true;
  error.value = false;
  products.value = [];

  store.dispatch("product/searchProducts", {
      text: textToSearch.value,
      description: description.value || "",
      currentPage: 1,
      construpUserId: getConstrupUserId,
    })
    .then((response) => {
      products.value = response.data.data.data || [];
    })
    .catch(() => {
      error.value = true;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<style scoped>
:deep(.list-md) {
  padding: 0;
}

:deep(.item-fill-none .in-list) {
  font-size: 12px;
}

ion-avatar {
  --border-radius: 4px;
}
</style>
