export default [
  {
    path: '/realizar-pagamento-cartao/:id',
    name: 'payment-card',
    component: () => import('../../views/payment/PaymentCard.vue')
  },
  {
    path: '/realizar-pagamento-pix/:id',
    name: 'payment-pix',
    component: () => import('../../views/payment/PaymentPix.vue')
  },
  {
    path: '/realizar-pagamento-all/:id',
    name: 'payment-all',
    component: () => import('../../views/payment/PaymentAll.vue')
  },
  {
    path: '/realizar-pagamento-wallet/:id',
    name: 'payment-wallet',
    component: () => import('../../views/payment/PaymentWallet.vue')
  },
  {
    path: '/selecionar-meio-pagamento/:id',
    name: 'select-payment',
    component: () => import('../../views/payment/SelectPayment.vue')
  },
];
