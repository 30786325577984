<template>
  <div
    class="loading"
    v-if="loading"
    :style="
      fullscreen
        ? `
          position: fixed;
          width: 100%;
          height: 100vh;
          z-index: 100;
          background: #22222245;
          top: 0;
          right: 0;
          left: 0;
          `
        : ''
    "
  >
    <ion-spinner :name="name" color="primary"></ion-spinner>
  </div>
</template>

<script>
import { IonSpinner } from "@ionic/vue";

export default {
  name: "LoadingComponent",
  components: {
    IonSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "crescent",
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
