export default [
  {
    path: '/professional/guide',
    name: 'professional-guide',
    component: () => import('../views/professionalGuide/Index.vue'),
  },
  {
    path: '/professional/profile/:id',
    name: 'professional-profile',
    component: () => import('../views/professionalGuide/Profile.vue'),
  },
];
