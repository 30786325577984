// import api from "@/config/api";
import construpApi from "@/config/construpApi";
import api from "@/config/api";

export default {
  getAllCities({ commit }) {
    return construpApi.get("/cities").then((response) => {
      commit("setCities", response.data.data);
    });
  },
  getPrivateCities({ commit }) {
    return construpApi.get("/cities").then((response) => {
      commit("setCities", response.data.data);
    });
  },
  getPublicCities({ commit }) {
    return construpApi.get("/cidades").then((response) => {
      commit("setCities", response.data.data);
    });
  },
  getAllCitiesByUfId({ commit }, ibgeUfId) {
    return construpApi.get(`/uf/${ibgeUfId}/cities`).then((response) => {
      commit("setIbgeCities", response.data);
    });
  },
  async getAllSegments({ commit }) {
    const response = await construpApi.get("/segmentos-all");
    commit("setSegments", response.data.data);
  },
  async getAllTypeProfessions({ commit }) {
    const response = await construpApi.get('/tipo-profissoes');
    commit('setTypeProfessions', response.data.data);
  },
  consultCep(_, cep) {
    return construpApi.get(`cep/${cep}/consult`);
  },
  verifyIfCityExistsByIbgeCode(_, cityData) {
    return construpApi.post('/exists/city', cityData);
  },
  getAddressShopkeeper(_, shopkeeper) {
    return api.get(`/dados/shopkeeper/${shopkeeper}`);
  },
  validationCode(_, code) {
    return construpApi.get(`/get-clerk-app/${code}`);
  },
  getDataClerk(_, id) {
    return construpApi.get(`/get-clerk-data/${id}`);
  },
  sendChatQuestion({}, data) {
    return api.post('/chatgpt', data);
  },
};
