<template>
  <ion-page>
    <ion-header>
      <menu-toggle
        :show-back-icon="hasToShowBackIcon"
      ></menu-toggle>
    </ion-header>
    <ion-content fullscreen scroll-y>
      <ion-grid v-if="showBackButton">
        <ion-row>
          <ion-col size="12">
            <base-button
              expand="none"
              color="dark"
              fill="clear"
              :isLoading="loading"
              :showOnlyIcon="backButtonShowOnlyIcon"
              :text="backText"
              :icon="backIcon"
              @click="backTo ? $router.push(backTo) : $router.go(-1)"
            ></base-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <slot name="loading">
        <loading-component
          v-if="loading"
          :loading="loading"
        ></loading-component>
      </slot>
      <slot name="refresher">
        <ion-refresher
          slot="fixed"
          @ionRefresh="$emit('onRefresh', $event)"
          v-if="useRefresher"
        >
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
      </slot>
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import { chevronBack } from "ionicons/icons";
import { mapGetters } from "vuex";
import { useRoute } from "vue-router";
import { computed } from 'vue';

export default {
  name: "BaseLayout",
  emits: ["onRefresh"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backText: {
      type: String,
      default: "Voltar",
    },
    backIcon: {
      default: chevronBack,
    },
    backButtonShowOnlyIcon: {
      type: Boolean,
      default: false,
    },
    backTo: {
      type: [String, Object],
      required: false,
    },
    useRefresher: {
      type: Boolean,
      default: false,
    },
    showBackIcon: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters("customization", ["getBackgroundColor"]),
    hasToShowBackIcon: function () {
      if (this.showBackIcon !== undefined) return this.showBackIcon;

      return this.route.name !== "home";
    },
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
  },
};
</script>