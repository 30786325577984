export default {
  setConstrupUserId(state, id) {
    state.construpUserId = id;
  },
  setCities(state, cities) {
    state.cities = cities;
  },
  setIbgeCities(state, ibgeCities) {
    state.ibgeCities = ibgeCities;
  },
  setSegments(state, segments) {
    state.segments = segments;
  },
  setTypeProfessions(state, typeProfessions) {
    state.typeProfessions = typeProfessions;
  },
};
