<template>
  <ion-modal ref="modal" v-if="opened" :is-open="opened" trigger="open-modal">
    <ion-header class="">
      <ion-toolbar class="no-change-color">
        <ion-buttons slot="start">
          <ion-button @click="toggleOpen({})">Fechar</ion-button>
        </ion-buttons>
        <ion-title>Localização atual</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="">
      <LocalizationDataCard />
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonButton,
  IonButtons,
} from "@ionic/vue";

import LocalizationDataCard from './LocalizationDataCard.vue';

export default {
  name: "SetCurrentLocalizationModal",
  data() {
    return {
      opened: false,
    };
  },

  methods: {
    toggleOpen({ id }) {
      this.opened = !this.opened;
    },
    clear() {
      this.toggleOpen({});
    },
  },
  components: {
    IonModal,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonButton,
    IonButtons,
    LocalizationDataCard,
  },
};
</script>
