import Axios from "axios";
import { Geolocation } from "@capacitor/geolocation";

import { alertController } from "@ionic/vue";
import { useStore } from "vuex";

export default function () {
  const store = useStore();

  /**
   * @param {Number} latitude
   * @param {Number} longitude
   * @returns
   */
  async function getCitiesByCoords(latitude, longitude) {
    if (!latitude || !longitude) {
      console.error("Missing latitude or longitude");
      return {};
    }

    const queryString = `${latitude}+${longitude}`;

    const getCitiesResponse = await Axios.get(
      `https://api.opencagedata.com/geocode/v1/json?q=${queryString}&key=610f77b3d3d24bc384a962a9a35895fb`
    );

    return getCitiesResponse.data.results[0] || {};
  }

  async function getCurrentPosition(showAlert = true) {
    return Geolocation.getCurrentPosition()
      .then(async (coordinates) => {
        await handleLocation(
          coordinates.coords.latitude,
          coordinates.coords.longitude
        );

        return coordinates || {};
      })
      .catch(async (error) => {
        console.error(error);

        if (error.code === 1 && showAlert) {
          await showDeniedGeolocation();
          return {};
        }

        return {};
      });
  }

  async function checkPermission(showAlert = true, loadCurrentPosition = true) {
    const status = await Geolocation.checkPermissions();

    if (status.location !== "granted" && showAlert) {
      await showDeniedGeolocation();
      return;
    }

    if (loadCurrentPosition) {
      await getCurrentPosition();
    }
  }

  async function requestPermission(showAlert = true) {
    try {
      await Geolocation.requestPermissions();
      await checkPermission(showAlert);
    } catch (ex) {

      if (showAlert) {
        await showErrorInRequestPermission();
      }
    }
  }

  function handleLocationSave({
    cep,
    state,
    stateName,
    cityName,
    latitude,
    longitude,
    fullAddress,
    street,
    district,
    fromShopkeeper,
  }) {
    const geolocation = {
      cep,
      state,
      stateName,
      cityName,
      latitude,
      longitude,
      fullAddress,
      street,
      district,
      fromShopkeeper,
    };

    store.commit("geolocation/setGeolocation", geolocation);
  }

  //#region Private functions

  async function showDeniedGeolocation() {
    const alert = await alertController.create({
      header: "Localização não permitida",
      subHeader: "Permita o acesso da localização",
      message:
        "É necessário permitir o acesso da localização do seu dispositivo ao app. Deseja tentar novamente?",
      buttons: [
        {
          text: "Não",
          role: "close",
          handler: () => {},
        },
        {
          text: "Sim",
          role: "confirm",
          handler: () => {
            requestPermission();
          },
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async function showErrorInRequestPermission() {
    const alert = await alertController.create({
      header: "Erro",
      subHeader: "Erro ao acessar a localização do dispositivo",
      message:
        "Houve um erro ao acessar a localização do dispositivo ao app. Por favor, tente novamente mais tarde",
      buttons: [
        {
          text: "Fechar",
          role: "close",
          handler: () => {},
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async function handleLocation(latitude, longitude) {
    const response = await getCitiesByCoords(latitude, longitude);

    const { components = {} } = response || {};

    if (!components.state_code || !components.postcode) {
      console.error("Sem dados de UF e CEP");
      return;
    }

    let city = "";
    const road = components.road ? components.road + ", " : "";

    if (components.town) {
      city = components.town;
    } else if (components.city) {
      city = components.city;
    } else if (components.village) {
      city = components.village;
    }

    const fullAddress = road + city + "-" + components.state_code;

    const data = {
      cep: components.postcode || "",
      state: components.state_code || "",
      stateName: components.state || "",
      cityName: city,
      latitude: latitude,
      longitude: longitude,
      street: road,
      district: components.suburb || "",
      fullAddress: fullAddress.replace("unnamed road,", ""),
    };

    handleLocationSave(data);

    return data || {};
  }

  //#endregion

  return {
    store,
    getCitiesByCoords,
    getCurrentPosition,
    checkPermission,
    requestPermission,
    handleLocationSave,
  };
}
