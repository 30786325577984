<template>
  <ion-row
    class="ion-align-items-center"
    style="background-color: black"
    @click="$refs.localizationModal.toggleOpen({})"
  >
    <ion-col size="10" class="p-2 fs-14">
      <ion-text
        color="light"
        v-if="
          getCurrentGeolocation.cityName &&
          !getCurrentGeolocation.fromShopkeeper
        "
      >
        Enviar para
        <span v-if="!getCurrentGeolocation.fullAddress">
          {{ (getCurrentGeolocation.cityName || "Sem nome").toUpperCase() }} -
          {{ getCurrentGeolocation.cep }}
        </span>
        <span v-else>
          {{ getCurrentGeolocation.fullAddress }}
        </span>
      </ion-text>
      <ion-text color="light" v-else> Informe sua localização </ion-text>
    </ion-col>
    <ion-col size="2" class="text-right">
      <ion-icon :icon="icons.chevronForwardOutline"></ion-icon>
    </ion-col>
  </ion-row>
  <SetCurrentLocalizationModal ref="localizationModal" />
</template>

<script>
import { mapGetters } from "vuex";

import { IonRow, IonCol, IonIcon, IonText } from "@ionic/vue";

import { chevronForwardOutline } from "ionicons/icons";

import SetCurrentLocalizationModal from "../SetLocalization/SetCurrentLocalizationModal.vue";

export default {
  name: "DefineLocalization",
  data() {
    return {
      icons: {
        chevronForwardOutline,
      },
    };
  },
  computed: {
    ...mapGetters("geolocation", ["getCurrentGeolocation"]),
  },
  components: {
    IonRow,
    IonCol,
    IonIcon,
    IonText,
    SetCurrentLocalizationModal,
  },
};
</script>
