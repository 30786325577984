import api from "@/config/api";

export default {
  getBanners({ commit }, construpUserId) {
    return api.get(`/app/banners/${construpUserId}`)
      .then((response) => {
        commit('setBannersHome', (response.data || {}).data || []);
        return response;
      });
  },
};
