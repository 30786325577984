export default {
  getMenuHeaderColor(state) {
    return state.menuHeaderColor;
  },
  getBackgroundColor(state) {
    return state.backgroundColor;
  },
  getIconColor(state) {
    return state.iconColor;
  },
  getFontColor(state) {
    return state.fontColor;
  },
  getProjectIcon(state) {
    return state.projectIcon;
  },
  getProjectLogo(state) {
    return state.projectLogo;
  },
  getLojista(state) {
    return state.lojista;
  },
  getVersionBlock(state) {
    return state.versionBlock;
  },
  getVersion(state) {
    return state.version;
  },
  getLinkAndroid(state) {
    return state.linkAndroid;
  },
  getLinkApple(state) {
    return state.linkApple;
  },
  getRegraPontos(state) {
    return state.regraPontos;
  }
};
