export default {
    menuHeaderColor: '',
    backgroundColor: '',
    iconColor: '',
    fontColor: '',
    projectIcon: '',
    projectLogo: '',
    version: '',
    versionBlock: '',
    linkAndroid: '',
    linkApple: '',
    regraPontos: '',
    lojista: '',
};
