<template>
  <ion-button
    class="ion-activatable ripple-parent"
    :color="color"
    :size="size"
    :disabled="isLoading"
    :fill="fill"
    :expand="expand"
    :download="download"
    :href="href"
    @click="redirectToRoute()"
  >
    <slot>
      <ion-label v-if="text && !iconOnly" :class="[hasIcon ? 'ml-2' : '']">{{
        text
      }}</ion-label>
    </slot>

    <ion-icon
      v-if="hasIcon"
      :slot="iconOnly ? 'icon-only' : 'start'"
      :ios="iosIcon || icon"
      :md="mdIcon || icon"
      :style="`color: ${getIconColor || '#000'}`"
    ></ion-icon>
    <ion-spinner
      v-if="isLoading"
      slot="end"
      :class="iconOnly ? '' : 'ion-margin-start'"
      :name="spinnerName"
    ></ion-spinner>
    <ion-ripple-effect type="unbounded"></ion-ripple-effect>
  </ion-button>
</template>

<script>
import {
  IonButton,
  IonLabel,
  IonIcon,
  IonSpinner,
  IonRippleEffect,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import { mapGetters } from "vuex";

export default {
  name: "ButtonComponent",
  components: {
    IonButton,
    IonLabel,
    IonIcon,
    IonSpinner,
    IonRippleEffect,
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
      required: false,
    },
    expand: {
      type: String,
      default: "block",
    },
    size: {
      type: String,
      default: "medium",
    },
    icon: {
      default: null,
      required: false,
    },
    iosIcon: {
      default: null,
      required: false,
    },
    mdIcon: {
      default: null,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    spinnerName: {
      type: String,
      default: "crescent",
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: String,
      default: "solid",
    },
    /** Define para que rota o botão vai redirecionar */
    to: {
      type: String,
      required: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: false,
    },
  },
  setup() {
    const router = useRouter();

    return { router };
  },
  computed: {
    ...mapGetters("customization", ["getIconColor"]),
    hasIcon() {
      return this.icon || this.iosIcon || this.mdIcon;
    },
  },
  methods: {
    redirectToRoute() {
      if (!this.to) return;

      this.router.push(this.to);
    },
  },
};
</script>