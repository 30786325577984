export default [
  {
    path: '/empresa/dados',
    name: 'empresa-data',
    meta: { valid: "login" },
    component: () => import('../../views/empresa/EmpresaData.vue')
  },  
  {
    path: '/cliente/enderecos',
    name: 'clients-address',
    component: () => import('../../views/client/ClientAddress.vue')
  },  
  {
    path: '/cliente/cartoes',
    name: 'clients-cards',
    component: () => import('../../views/client/ClientCards.vue')
  },  
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../../views/notification/Index.vue')
  }, 
  {
    path: '/client/wallet',
    name: 'clients-wallet',
    component: () => import('../../views/client/Wallet.vue')
  }, 
];
