import SOCIAL_MEDIAS from '@/enums/SocialMedias.js';

export default {
    getSocialMedias(state) {
        return state.socialMedias;
    },
    getWhatsappTelephone(state) {
        if (!state.socialMedias || !state.socialMedias.length) return '';

        const whatsappObject = state.socialMedias.find(x => x.type === SOCIAL_MEDIAS.WHATSAPP);
        return whatsappObject ? whatsappObject.link : '';
    }, 
};
