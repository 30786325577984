export default [
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/blog/Index.vue'),
  },
  {
    path: '/blog/:id',
    name: 'blog-tip',
    component: () => import('../views/blog/Tip.vue'),
  },
];
