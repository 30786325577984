import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL+'api';

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token || null}`;
  return config;
});

export default api;
