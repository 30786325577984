export default [
  {
    path: '/carrinho',
    name: 'cart',
    meta: { valid: "login" },
    component: () => import('../../views/cart/Cart.vue')
  },  
  {
    path: '/orcamento/:id',
    name: 'orcamento',
    meta: { valid: "login" },
    component: () => import('../../views/cart/Orcamento.vue')
  }, 
];