<template>
  <ion-header>
    <ion-toolbar :style="`--background: ${getMenuHeaderColor}`" mode="md">
      <ion-row class="divMenu">
        <ion-col size="2" id="main">
          <ion-menu-toggle>
            <ion-icon
              :icon="Icon.menu"
              class="iconMenu"
              :style="`color: ${getIconColor}`"
            ></ion-icon>
          </ion-menu-toggle>
        </ion-col>
        <ion-col size="8" @click="nextUrl('home')">
          <img :src="`${logo}`" class="logoImg" :style="logoHeaderClass" />
        </ion-col>
        <ion-col size="2" @click="nextUrl('cart')">
          <span class="countCart" :style="`background-color: ${getIconColor};color:${getMenuHeaderColor}`">
            {{ itensCart }}
          </span>
          <ion-icon
            :icon="Icon.cart"
            class="iconMenuCart"
            :style="`color: ${getIconColor}`"
          ></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
        id="product-popover-base"
        class="mx-3"
      >
          <ion-col
            v-if="showBackIcon"
            size="1"
            style="display: flex; align-items: center"
            class="pl-0"
          >
            <div class="fs-24" @click.stop="router.go(-1)">
              <ion-icon
                class="backIcon"
                :icon="Icon.chevronBackOutline"
                :style="`color: ${getIconColor}`"
              ></ion-icon>
            </div>
          </ion-col>

          <ion-col class="searchBar">
            <div
              class="cardSearch"
              :style="`border: 2px solid ${getIconColor}; display: flex; align-items: center`"
            >
              <ion-input
                v-model="inputSearch"
                autocomplete="off"
                placeholder="Buscar produto"
                @click="validPopover = true"
                @keyup.enter="searchInput()"
              ></ion-input>
              <ion-icon
                @click="searchInput()"
                :icon="Icon.search"
                class="iconSearch"
                :style="`color: ${getIconColor}`"
              ></ion-icon>
            </div>
          </ion-col>
        <ion-col
          size="2"
          style="display: flex; align-items: center"
          class="pl-0 iconVoice"
        >
          <div @click="openModalVoice()" class="cardSearchMic mr-2">
            <ion-icon 
              :icon="Icon.micOutline" 
              class="iconCamera" 
              :style="`color: ${getIconColor}`"
            ></ion-icon>
          </div>
          <div @click="scanBarcode()" class="cardSearchCam">
            <ion-icon
              :icon="Icon.qrCode"
              class="iconCamera"
              :style="`color: ${getIconColor}`"
            ></ion-icon>
          </div>
        </ion-col>

        <ProductListPopover
          ref="productListPopover"
          :text-to-search="inputSearch"
          :valid-popover="validPopover"
          target-element-id="product-popover-base"
          style="margin-top: -14px"
        />
      </ion-row>
      <define-localization v-if="showLocalization" />
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";

import formatStyle from "@/composition/formatStyle";
import useToast from "@/config/useToast";

import VoiceTextComponent from "../Product/VoiceTextComponent.vue";
import DefineLocalization from "../Header/DefineLocalization.vue";
import ProductListPopover from "../Product/ProductListPopover.vue";

import {
  IonPage,
  IonHeader,
  IonRow,
  IonCol,
  IonToolbar,
  IonIcon,
  IonMenuToggle,
  IonInput,
  modalController,
} from "@ionic/vue";

import {
  menu,
  cart,
  search,
  qrCode,
  close,
  home,
  list,
  micOutline,
  chevronBackOutline,
} from "ionicons/icons";
import eventBus from "@/composition/eventBus";
import { useRouter } from "vue-router";

export default {
  name: "BaseLayout",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonRow,
    IonCol,
    IonIcon,
    BarcodeScanner,
    IonMenuToggle,
    IonInput,
    DefineLocalization,
    ProductListPopover,
  },

  props: {
    menuType: {
      type: Function,
      required: false,
    },
    showBackIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      barcodeScanner: new BarcodeScanner(),
      logoHeaderClass: "",
      logo: "",
      inputSearch: "",
      imgBase64: "",
      showLocalization: true,
      validPopover: false,
      itensCart: 0,
    };
  },
  computed: {
    ...mapGetters("notification", ["getNotificationsQuantity"]),
    ...mapGetters("general", ["getConstrupUserId"]),
    ...mapGetters("customization", [
      "getFontColor",
      "getIconColor",
      "getMenuHeaderColor",
    ]),
  },
  setup() {
    const loading = ref(false);
    const { openToast } = useToast();
    const router = useRouter();

    const Icon = ref({
      menu,
      cart,
      search,
      qrCode,
      close,
      home,
      list,
      micOutline,
      chevronBackOutline,
    });

    return {
      Icon,
      loading,
      openToast,
      router,
    };
  },

  mounted() {
    this.countItensCart();

    var img = JSON.parse(localStorage.getItem("img"));
    this.logoHeaderClass = formatStyle(img);
    this.logo = img.logo;

    eventBus().emitter.on("hide-localization", () => {
      this.showLocalization = false;
    });

    eventBus().emitter.on("cart-count-itens", () => {
      this.countItensCart();
    });
  },
  beforeUnmount() {
    eventBus().emitter.off("hide-localization", () => {
      this.showLocalization = false;
    });

    eventBus().emitter.off("cart-count-itens", () => {
      this.countItensCart();
    });
  },

  methods: {
    nextUrl(value) {
      this.router.push({ name: value });
    },
    countItensCart() {
      this.itensCart =  0;

      var itens = JSON.parse(localStorage.getItem("cartItens"));
      if(itens && itens.length > 0) {
        this.itensCart = itens.length;
      }
    },
    searchInput(base64 = "") {
      this.validPopover = false;
      this.router.push({
        name: "list-product-search",
        params: {
          textToSearch: this.inputSearch,
          base64Image: base64 || "",
        },
      });
    },
    async scanBarcode() {
      await this.barcodeScanner.scan().then((barcodeData) => {
        this.validateBarCode(barcodeData.text);
      });
    },
    validateBarCode(code) {
      const data = {
        code: code,
        construpUserId: this.getConstrupUserId,
      };

      this.$store
        .dispatch("product/openBarCode", data)
        .then((response) => {
          if (response.data.data[0].id) {
            this.router.push({
              name: "detalhesProdutos",
              params: { id: response.data.data[0].id },
            });
          } else {
            this.openToast("Item não encontrado", "danger");
          }
        })
        .catch(() => {
          this.openToast("Item não encontrado", "danger");
        });
    },
    async openModalVoice() {
      const modal = await modalController.create({
        component: VoiceTextComponent,
      });
      modal.present();
    },
  },
};
</script>

<style scoped>
.cardSearchCam {
  /* position: absolute; */
  bottom: 15px;
}
.cardSearchMic {
  /* position: absolute; */
  bottom: 15px;
}

.iconMenu {
  margin-top: 8px;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  font-size: 25px;
}
.iconMenuCart {
  position: relative;
  right: 5px;
  margin-top: 8px;
  margin-left: 14px;
  width: 32px;
  height: 32px;
  font-size: 25px;
}

@media (min-width: 768px) {
  .iconMenuCart {
  position: relative;
  left: 30px;
  margin-top: 8px;
  margin-left: 14px;
  width: 32px;
  height: 32px;
  font-size: 25px;
}

.iconMenu {
  margin-top: 8px;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  font-size: 25px;
}

.backIcon {
  font-size: 40px;
}
}

.divMenu {
  display: flex;
  align-items: center;
}
.logoImg {
  display: block;
  margin: 0 auto;
}
.cardSearch {
  display: flex;
  background: #fff;
  height: 30px;
  border-radius: 25px;
  padding: 0 4px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.iconCamera {
  font-size: 28px;
  margin-top: -6px;
  margin-left: -3px;
}

@media (min-width: 768px) {
  .iconCamera {
  font-size: 28px;
  margin-top: -6px;
  margin-right: 7px;
}

  .iconVoice {
    left: 25px;
  }
}

.countCart {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  border-radius: 50%;
  font-size: 12px;
  right: 25%;
  top: 8px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
} 

@media(min-width: 768px) {
  .countCart {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  border-radius: 50%;
  font-size: 12px;
  right: 35%;
  top: 8px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
} 

@media(min-width: 768px) {
  .searchBar {
    left: 2%;
  }
}
}
</style>