export default {
  setAllCustomizations(state, customizations) {
    const {
      menuHeaderColor,
      backgroundColor,
      iconColor,
      fontColor,
      projectIcon,
      projectLogo,
      version,
      versionBlock,
      linkAndroid,
      linkApple,
      regraPontos,
      lojista,
    } = customizations || {};

    state.menuHeaderColor = menuHeaderColor;
    state.backgroundColor = backgroundColor;
    state.iconColor = iconColor;
    state.fontColor = fontColor;
    state.projectIcon = projectIcon;
    state.projectLogo = projectLogo;
    state.version = version;
    state.versionBlock = versionBlock;
    state.linkAndroid = linkAndroid;
    state.linkApple = linkApple;
    state.regraPontos = regraPontos;
    state.lojista = lojista;
  },
};
