import api from '@/config/api';

/* eslint-disable */
export default {
  login({}, userCredentials) {
    return api.post('/auth/login', userCredentials);
  },
  logout({}) {
    return api.post('/auth/logout');
  },
  rescuePassword({}, data) {
    return api.post('/passwrd-recovery', data)
  },
  updatePassword({}, data){ 
    return api.post('/validate-passwrd', data)
  },
  updateData({}, data) {
    return api.post(`/user/edit/${data.id}`, data);
  },
  newAddress({}, data) {
    return api.post(`/address/new`, data);
  },
  getAddress({}, id){
    return api.get(`/address/find-one/${id}`);
  },
  getPontosHistorico({}, id){
    return api.get(`/meus-pontos/${id}`);
  },
  getPremioDisponivel({}, data){
    return api.get(`/premio-disponivel/${data.lojistaId}/${data.userId}`);
  },
  getPontosExterno({}, id){
    return api.get(`/meus-pontos-externo/${id}`);
  },
  buscarPontosExterno({}, data){
    return api.post('/buscar-pontos-externo', data);
  },
  getPremioSolicitados({}, userId){
    return api.get(`/premio-solicitado/${userId}`);
  },
  solicitarPremio({}, data){
    return api.post('/solicitar-premio', data);
  },
  updateAddress({}, data) {
    return api.post(`/address/edit/${data.id}`, data);
  },
  newPhone({}, data) {
    return api.post('/phone/new', data);
  },
  updatePhone({}, data) {
    return api.post(`/phone/edit/${data.id}`, data.data);
  },
  deletePhone({}, id) {
    return api.delete(`/phone/delete/${id}`);
  },
  getAddressCep({}, cep) {
    return api.get(`https://viacep.com.br/ws/${cep}/json`);
  },
  deliveryAddress({}, data) {
    return api.post(`/address/new-address`, data);
  },
  deliveryUpdateAddress({}, data) {
    return api.post(`/address/edit-address/${data.id}`, data);
  },
  deliveryDeleteAddress({}, id) {
    return api.delete(`/address/delete-address/${id}`);
  },
  deliveryGetAddress({}, user) {
    return api.get(`/address/all-address/${user}`);
  },
  getFreight({}, construpUser){
    return api.get(`/freights/${construpUser}`);
  },
  buscarTipos({}, construpUser){
    return api.get(`/types-usuarios-cadastro/${construpUser}`);
  },
  getRequiredFields({}, construpUser){
    return api.get(`/customization/get-fields-require/${construpUser}`);
  },
  saveIdClerk({}, data) {
    return api.post('/balconista/salvar-id-clerk', data);
  },
};
