import api from "@/config/api";

export default {
  getNotification(_, { userId, notificationId }) {
    return api.get(`/notifications/${userId}?notificationId=${notificationId}`);
  },
  listNotification(
    { commit },
    {
      construpUserId,
      userId,
      itemsPerPage = 10,
      currentPage = 1,
      orderByDesc = "updated_at",
    }
  ) {
    return api
      .get(
        `/notifications/all/${construpUserId}?userId=${userId}&itemsPerPage=${itemsPerPage}&page=${currentPage}&orderByDesc=${orderByDesc}`
      )
      .then((response) => {
        const { data } = response.data.data || {};

        commit("setNotifications", data || []);
        commit("setNotificationsQuantity", (data || []).length);

        return response.data.data || {};
      });
  },
};
