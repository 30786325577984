export default [
  {
    path: '/professional/data',
    name: 'professional-data',
    meta: { valid: "login" },
    component: () => import('../../views/professional/ProfessionalData.vue')
  },  
  {
    path: '/request-approval',
    name: 'request-approval',
    component: () => import('../../views/professional/RequestApproval.vue'),
  },
];
