<template>
  <div>
    <ion-row class="container">
      <ion-col size="12" class="text-center" v-if="validUrl != 'details-product'">
        <span class="titlePromotions text-center" :style="`color: ${getFontColor}`">{{
          items.name
        }}
       <ion-card-content v-if="timeRequest != null && timeValidation()">
        <vue3-flip-countdown
          mainFlipBackgroundColor="#000"
          secondFlipBackgroundColor='#000'
          mainColor="white"
          countdownSize="1rem"
          labelSize=".8rem"
          :labels="{days: 'Dias', hours: 'Hrs.', minutes: 'Min.', seconds: 'Seg.' }"
          :deadline="timeRequest"
        />
      </ion-card-content>
        </span>
      </ion-col>
      <ion-col class="cardFlex">
        <div class="card" v-for="item in items.menu_product" :key="item" v-show="item.produto_fotos && item.produto_fotos[0]">
          <ion-card class="ionCard" @click="showProduct(item)" v-if="item">
            <div :style="`
              color: ${getFontColor};
              background-color: ${getBackgroundColor};
              padding: 6px 10px;
              border: 1px solid ${getIconColor};
              border-radius: 4px 4px 0 0;
              text-align: center;`
            ">
              Preço para {{ getCurrentGeolocation.state }}
            </div>
            <div class="cardImg" v-if="item.produto_fotos">
              <ion-img :src="formatUrlImg(item.produto_fotos[0].foto_png, item.origem)" />
              <div
                v-if="item.withdrawal_in_one_day"
                style="position: absolute; bottom: 10px; right: 5%"
              >
                <ion-icon
                  :icon="Icon.timeOutline"
                  :style="`font-size: 30px; color: ${getIconColor}`"
                ></ion-icon>
                <span
                  class="ion-text-bold"
                  :style="`
                    font-size: 10px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: white;
                    font-weight: bold;
                    color: ${getIconColor}
                  `"
                  >24h</span
                >
              </div>
            </div>
            <div class="cardDescription">
              <ion-row>
                <span class="titleProduct"
                  >{{ (item.produto || '') }}</span
                >
              </ion-row>
              <div v-if="(item.status == 1) && (item.manage_stock == false || (item.manage_stock == true && item.quantity_stock > 0))">
                <div v-if="getAboutCompany.active_price == true || validShowPrice == true">
                  <div 
                    class="priceRow" 
                    v-if="item.discont || 
                    generalDiscount.pix > 0 || 
                    generalDiscount.bank > 0 ||
                    generalDiscount.card > 0"
                  >
                    <ion-row>
                      <span class="discontProduct" v-if="item.customPriceByState && item.ufState">R$ {{ formatMoneyUn(item.customPriceByState) }}</span>
                      <span class="discontProduct" v-else>R$ {{ formatMoneyUn(item.price_sales_format_1) }}</span>
                      
                      <span
                        v-if="item.discont > biggestDiscount" 
                        class="percentageProduct"
                      ><ion-icon 
                          :icon="Icon.arrowDown"
                          :style="`color: ${getIconColor}`"
                        ></ion-icon>{{ item.discont }}%
                      </span>

                      <span 
                        v-else-if="biggestDiscount > 0"
                        class="percentageProduct"
                      ><ion-icon 
                          :icon="Icon.arrowDown"
                          :style="`color: ${getIconColor}`"
                        ></ion-icon>{{ biggestDiscount }}%
                      </span>

                    </ion-row>
                    <ion-row>
                      <span class="priceProduct" v-if="(item.customPriceByState && item.ufState) && (biggestDiscount != '' || item.discont != '')">
                        R${{ formatMoneyUn(item.customPriceByState - (item.customPriceByState * (biggestDiscount != '' ? biggestDiscount : item.discont / 100))) }}
                      </span>
                      <span class="priceProduct" v-else-if="(item.customPriceByState && item.ufState) && (biggestDiscount == '' || item.discont == '')">
                        R${{ formatMoneyUn(item.customPriceByState) }}
                      </span>
                      <span class="priceProduct" v-else-if="biggestDiscount > item.discont">
                        R$ {{ formatMoneyUn(item.price_sales_format_1 - (item.price_sales_format_1 * (biggestDiscount / 100))) }}
                      </span>
                      <span class="priceProduct" v-else>
                        R$ {{ formatMoneyUn(item.price_sales_format_1 - (item.price_sales_format_1 * (item.discont / 100))) }}
                      </span>
                    </ion-row>
                  </div>  
                  <div v-else class="priceRow">
                    <ion-row>
                      <span class="priceProduct" v-if="item.customPriceByState && item.ufState">
                        R$ {{ formatMoneyUn(item.customPriceByState) }}
                      </span>
                      <span class="priceProduct" v-else>
                        R$ {{ formatMoneyUn(item.price_sales_format_1) }}
                      </span>
                    </ion-row>
                  </div>
                  <ion-row>
                    <span class="paymentProduct">
                      <b  v-for="(payment, index) in formPayment" :key="payment">
                        {{ payment }}<span v-if="index < (formPayment.length -1)">, </span>
                      </b>
                    </span>
                  </ion-row>
                </div>
              </div>
              <div v-if="(item.status == 2) || (item.manage_stock == true && item.quantity_stock <= 0)" class="indisponivel">
                <button class="indisponivelBtn">Item indisponível</button>
              </div>
            </div>
          </ion-card>

          <div 
            class="mostrarPreco"
            @click="this.router.push({name: 'login'})"
            v-if="getAboutCompany.active_price == false && validShowPrice == false"
          >
            <span :style="`background: ${getMenuHeaderColor};color: ${getIconColor}`" class="mostrarPrecoText">
              <ion-icon :icon="Icon.eye" :style="`color: ${getIconColor}`"></ion-icon>
              VER PREÇO
            </span>
          </div>

        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { ref } from "vue";
import { IonRow, IonCol, IonCard, IonIcon, IonImg, IonCardContent } from "@ionic/vue";
import { arrowDown, timeOutline, eye } from "ionicons/icons";
import { mapGetters } from "vuex";
import { useRouter } from 'vue-router';
import eventBus from "../../composition/eventBus";
export default {
  name: "CardProductComponent",
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonIcon,
    IonImg,
    IonCardContent
  },

  props: {
    items: {
      type: Object,
    },
    pix: {
      type: Boolean,
    },
    card: {
      type: Boolean,
    },
    bank: {
      type: Boolean,
    },
    generalDiscount: {
      type: Object,
    },
    timer: {
      type: String,
      default: "2022-01-01 00:00:00",
      required: false,
    },
  },

  data() {
    return {
      count: 8,
      validUrl: "",
      validShowPrice: true,
      formPayment:[],
      biggestDiscount: 0,
      timeRequest: '2022-01-01 00:00:00',
    };
  },
  setup() {
    const Icon = ref({
      arrowDown,
      timeOutline,
      eye
    });

    const router = useRouter();

    return {
      Icon,
      router,
    };
  },
  computed: {
    ...mapGetters("aboutCompany", ["getAboutCompany"]),
    ...mapGetters("customization", ["getIconColor", "getFontColor", "getBackgroundColor", "getMenuHeaderColor"]),
    ...mapGetters("geolocation", ["getCurrentGeolocation"]),
  },
  created() {
    this.validPrice();

    this.validUrl = this.$route.name;
    this.verifyDiscount()
  },
  mounted() {
    this.timeRequest = this.timer;
    if(this.timer == '0000-00-00 00:00:00') {
      this.timeRequest = '2022-01-01 00:00:00';
    }
    
    eventBus().emitter.on("valid-event-active-price", (type) => {
      this.validPrice(type);
    });
  },
  methods: { 

    timeValidation() {
      const countTimer = new Date(this.timeRequest);
      const currentTime = Date.now();
      if (countTimer.getTime() < currentTime) return false;
      return true;
    },
    
    validPrice(type='') {
      const user = JSON.parse(localStorage.getItem("user"));

      this.validShowPrice = false;
      if(user != null) {
        this.validShowPrice = true;
      }
      
      if(type == 'logout'){
        this.validShowPrice = false;
      }
    },
    verifyDiscount(){
      this.formPayment = [];

      if(this.card == true) {
        this.formPayment.push('Cartão');
      }

      if(this.bank == true) {
        this.formPayment.push('Boleto');
      }

      if(this.pix == true) {
        this.formPayment.push('Pix');
      }
      
      this.biggestDiscount =  Math.max(
        this.bank? this.generalDiscount.bank : 0, 
        this.pix ? this.generalDiscount.pix : 0, 
        this.card ? this.generalDiscount.card : 0,
      );

      // if(this.biggestDiscount > 0){
      //   if(this.card && this.biggestDiscount == this.generalDiscount.card) this.formPayment.push('cartão')
      //   if(this.bank && this.biggestDiscount == this.generalDiscount.bank) this.formPayment.push('boleto')
      //   if(this.pix && this.biggestDiscount == this.generalDiscount.pix) this.formPayment.push('pix')
      // }
    },
    formatMoneyUn(value) {
      var money = parseFloat(value);
      return money.toLocaleString('pt-br', {maximumFractionDigits: 2, minimumFractionDigits: 2});
    },
    formatUrlImg(urlImg, origem) {
      if (origem == 'Belenus') {
        return 'https://storage.googleapis.com/fotos-prd/'+urlImg;
      } else {
        return process.env.VUE_APP_FOTOS_URL + urlImg;
      }
    },
    showProduct(item) {
      if(item.familia && item.familia != null && item.familia != '') {
        this.$router.push({
          name: "show-product-list-1",
          params: { familia: item.familia },
        });
      } else {
        this.router.push({
          name: 'detalhesProdutos',
          params: { id: item.id },
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.mostrarPreco {
  z-index: 99;
  width: 100%;
  position: relative;
  bottom: 15%;
  text-align: center;
}
.mostrarPrecoText {
  padding: 5px 20px;
  border-radius: 4px;
}
.titlePromotions {
  font-size: 25px;
  margin-top: 10px;
  font-weight: bold;
}
.ionCard {
  margin-inline: 5px;
  /* padding-top: 5px; */
}
.cardFlex {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: -15px;
}
.card {
  min-width: 180px;
}

@media (min-width: 768px) {
  .card {
  min-width: 45%;
}
}
.cardImg {
  min-width: 80px;
  max-width: 150px;
  min-height: 80px;
  max-height: 150px;
  aspect-ratio: 1;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  padding: 20px;
}

.cardImg img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
}

.cardDescription {
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #000;
  min-height: 120px;
}
.discontProduct {
  font-size: 10px;
  margin-top: 3px;
  color: #444444;
  text-decoration: line-through;
}
.percentageProduct {
  margin-left: 8px;
  font-size: 10px;
  margin-top: 2px;
  color: #fff;
  padding: 1px 3px;
  background-color: rgb(42, 197, 55);
  border-radius: 25px;
}
.priceProduct {
  font-weight: 900;
  bottom: 25px;
}
.paymentProduct {
  font-weight: 300;
  font-size: 10px;
  position: absolute;
  bottom: 10px;
}
.indisponivel {
  position: absolute;
  bottom: 10px;
  width: 89%;
}

@media (min-width: 768px) {
  .indisponivel {
  position: absolute;
  bottom: 10px;
  width: 89%;
  right: 18px;
}
}

.indisponivelBtn {
  width: 100%;
  padding: 10px 0;
  border-radius: 3px;
  font-weight: 600;
}
.priceRow {
  position: absolute;
  bottom: 25px;
}
.titleProduct {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 45px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>